import Icon from "@mdi/react";
import { mdiClose, mdiChevronDown, mdiInformation } from "@mdi/js";
import useComponentVisible from "utils/useComponentVisible";
import { getOptionsByConfig } from "utils/products";
import "./SelectConfig.scss";
import { formatLabelSpecs } from "utils/general";
import { useState } from "react";

const SelectConfig = (props) => {
  const [tooltip, setTooltip] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible, toggling } =
    useComponentVisible(false);

  const handleChange = (option) => {
    props.setActiveConfig(props.target, option, option.reference);
    setIsComponentVisible(false);
  };

  const handleReset = (event) => {
    event.stopPropagation();
    if (!!props.tempConfig?.setting) {
      const option = props.tempConfig?.setting[props.target];
      props.resetActiveConfig(props.target, option?.reference);
    }
  };

  return (
    <div
      ref={ref}
      className={`configuration__select-config ${
        isComponentVisible ? "active" : ""
      }`}
    >
      <div className="select-line">
        <div className="placeholder" onClick={toggling}>
          <span>
            {formatLabelSpecs(props.target)}{" "}
            {props.value && props.value !== `undefined"` && `- ${props.value}`}
          </span>

          {props.value &&
          props.value !== `undefined"` &&
          !props.isAlreadySetted ? (
            <Icon
              path={mdiClose}
              size={0.8}
              onClick={(event) => handleReset(event)}
            />
          ) : (
            <Icon
              path={mdiChevronDown}
              size={1}
              className={isComponentVisible ? "active" : ""}
            />
          )}
        </div>

        <div
          onMouseEnter={() => setTooltip(true)}
          onMouseLeave={() => setTooltip(false)}
          className="tooltip-info"
        >
          <Icon path={mdiInformation} size={1} />

          {tooltip && (
            <div className="tooltip-description">
              {renderInfoSpec(props.target)}
            </div>
          )}
        </div>
      </div>

      {isComponentVisible && (
        <div className="options-container">
          {getOptionsByConfig(
            props.target,
            props.tempConfig.setting,
            props.type
          ).map((option, i) => {
            // console.log("option", option);
            return (
              <div
                key={`option_${i}`}
                onClick={() => handleChange(option)}
                className="option"
              >
                {props.target === "port" ? `${option.value}"` : option.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SelectConfig;

const renderInfoSpec = (spec) => {
  switch (spec) {
    case "body_size":
      return "Nominal diameter of coupling body";
    case "litre":
      return "Typical rated flow relative to the size, in accordance with ISO norm standard";
    case "port":
      return "Size of the port";
    case "thread":
      return "Port type to connect with fitting";
    default:
  }
};
