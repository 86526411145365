import linesConfiguration from "db/lines_options.json";
import { useDispatch, useSelector } from "react-redux";
import { setLines } from "reducer/index";
import "./SelectAreaBox.scss";
import SelectArea from "./select-area/SelectArea";

const SelectAreaBox = ({ setActiveCircle }) => {
  const dispatch = useDispatch();
  const lines = useSelector(({ lines }) => lines);
  const complementaryLines = createComplementaryLines(lines);
  const state = useSelector((state) => state);

  const handleChange = (target, value) => {
    dispatch(setLines(target, value));
    setActiveCircle([]);
  };

  return (
    <div className="navbar__select-area-box">
      {linesConfiguration.map((element, i) => (
        <SelectArea
          key={i}
          value={state[`is_${element.id}_set`] && Number(lines[element.id])}
          target={element.id}
          label={element.label}
          options={element.options}
          onChange={handleChange}
          max={complementaryLines[element.id]}
        />
      ))}
    </div>
  );
};

export default SelectAreaBox;

const createComplementaryLines = (lines) =>
  Object.entries(lines).reduce((acc, curr) => {
    const key = curr[0] === "hydraulic" ? "electric" : "hydraulic";
    return {
      ...acc,
      [key]: 10 - curr[1],
    };
  }, {});
