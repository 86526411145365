import { mdiCheck, mdiClose, mdiContentCopy, mdiHelpCircle } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCircles, setLines, setOperatorMode } from "reducer";
import "./TooltipCode.scss";
import { decode, getCode, isValidCode } from "utils/encodeDecode";
import useComponentVisible from "utils/useComponentVisible";
import { Popup } from "components/popups/Popup";
import ConfigurationsTest from "components/configurationsTest/ConfigurationsTest";

const TooltipCode = () => {
  const dispatch = useDispatch();
  const { ref, isComponentVisible, setIsComponentVisible, toggling } =
    useComponentVisible(false);
  const [code, setCode] = useState("");
  const [alertClipboard, setAlertClipboard] = useState(false);
  const [alertErrorCode, setAlertErrorCode] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const circles = useSelector(({ circles }) => circles);
  const activeCode = getCode(circles);

  const numberHydraulic = circles.filter(
    (circle) => circle?.type === "hydraulic"
  ).length;
  const numberElectric = circles.filter(
    (circle) => circle?.type === "electric"
  ).length;

  const handleSubmit = async () => {
    if (code.toUpperCase() === "TESTCONFIG") {
      setIsPopupOpen(true);
    } else if (code.toUpperCase() === "OPERATORMODE") {
      dispatch(setOperatorMode());
      setIsComponentVisible(false);
    } else if (isValidCode(code)) {
      dispatch(setLines("hydraulic", numberHydraulic));
      dispatch(setLines("electric", numberElectric));

      const decodeCode = decode(code);
      await dispatch(setCircles(decodeCode));
      await setIsComponentVisible(false);
      await setCode("");
    } else {
      setCode("");
      setAlertErrorCode(true);
      setTimeout(() => setAlertErrorCode(false), 800);
    }
  };

  const onChange = ({ target }) => setCode(target.value.toUpperCase());

  const handleEnter = (event) => {
    if (event.key === "Enter" && code !== "") {
      handleSubmit();
    }
  };

  const clipboard = () => {
    const copyText = document.getElementById("copyText").innerHTML;
    navigator.clipboard.writeText(copyText);
    setAlertClipboard(true);
    setTimeout(() => {
      setAlertClipboard(false);
    }, 800);
  };

  return (
    <div className="navbar__tooltip-code" ref={ref}>
      {isPopupOpen && (
        <Popup>
          <Icon
            className="close-popup"
            path={mdiClose}
            size={1}
            title="Close Popup"
            onClick={() => setIsPopupOpen(false)}
          />
          <ConfigurationsTest />
        </Popup>
      )}
      <Icon
        className={`tooltip-code ${isComponentVisible ? "active" : ""}`}
        path={mdiHelpCircle}
        size={1}
        onClick={toggling}
      />
      {isComponentVisible && (
        <div className="tooltip-box">
          {circles.some((element) => !!element) && (
            <div className="active-code">
              <span id="copyText">{activeCode}</span>

              <Icon
                path={mdiContentCopy}
                size={1}
                title="Copy Code"
                onClick={clipboard}
              />

              <div
                className={`feedback-clipboard ${
                  alertClipboard ? "active" : ""
                }`}
              >
                Copied Code
              </div>
            </div>
          )}

          <div className="input-code">
            <input
              type="text"
              value={code}
              onChange={onChange}
              onKeyPress={handleEnter}
              placeholder="code"
            />

            <Icon
              onClick={handleSubmit}
              path={mdiCheck}
              size={1}
              title="Confirm Code"
            />

            <div className={`feedback-error ${alertErrorCode ? "active" : ""}`}>
              Code not valid
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TooltipCode;
