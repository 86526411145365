import React, { useState } from "react";
import "./ConfigurationsTest.scss";
import { decode } from "utils/encodeDecode";
import { findMatching } from "utils/products";
import ConfigurationsTestList from "./ConfigurationsTestList";

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const ConfigurationsTest = () => {
  const [checked, setChecked] = useState([]);
  const [testing, setTesting] = useState(0);
  const [isTestStarted, setIsTestStarted] = useState(false);
  const [isTestEnded, setIsTestEnded] = useState(false);

  const checkIfPassed = (code, product) => {
    const decodeCode = decode(code);
    const res = findMatching(decodeCode)?.completePerfect.complete;
    // console.log("res", findMatching(decodeCode));
    const isPassed = res?.some((item) => item.code == product);
    return {
      code,
      product,
      found: res,
      isPassed,
    };
  };

  const checkIfAllPassed = async () => {
    setIsTestStarted(true);
    const checkedTmp = [];
    for (let index = 0; index < ConfigurationsTestList.length; index++) {
      setTesting(index);
      const singleConf = ConfigurationsTestList[index];

      const res = checkIfPassed(
        singleConf.configurationCode,
        singleConf.productCode
      );

      checkedTmp.push(res);

      setChecked(checkedTmp);

      await sleep(10);
    }
    setIsTestEnded(true);
  };

  // console.log("checked", checked);

  const numPassed = checked.filter((item) => item.isPassed).length;
  const numFailed = checked.filter((item) => !item.isPassed).length;
  const numTested = checked.length;
  const totalToTest = ConfigurationsTestList.length;
  
  //console.log(
  //  "failed",
  //  checked.filter((item) => !item.isPassed)
  //);
  return (
    <>
      <h1 className="test-title">Configurations Test</h1>
      {!isTestStarted && !isTestEnded && (
        <div
          style={{ display: "flex", alignSelf: "center" }}
          onClick={() => checkIfAllPassed()}
          className="btn outline"
        >
          Start test
        </div>
      )}
      <div>
        {isTestStarted && !isTestEnded && (
          <>
            <div>{ConfigurationsTestList[testing].configurationCode}</div>
            <div className="progress-bar-container">
              <div
                className="progress-bar"
                style={{
                  width: `${(numTested / totalToTest) * 100}%`,
                }}
              />
            </div>
          </>
        )}
        {isTestEnded && (
          <>
            {/* <div> TEST COMPLETED </div> */}

            <div className="success-results">{`Configurations successfully tested: ${numPassed}/${totalToTest}`}</div>
            {numFailed > 0 && (
              <>
                <div className="failed-results">{`Configurations tested with error: ${numFailed}/${totalToTest}`}</div>
              </>
            )}
            {numFailed > 0 && (
              <div className="errors-table">
                <div className="errors-table-header">
                  <div>Configuration</div>
                  <div>Expected Product</div>
                  <div>Found Product</div>
                </div>
                {checked
                  .filter((item) => !item.isPassed)
                  .map((item, index) => (
                    <div className="errors-table-row" key={index}>
                      <div>{item.code}</div>
                      <div>{item.product}</div>
                      <div>
                        {item.found?.map((item) => item.code).join(", ") ||
                          "NULL"}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ConfigurationsTest;
