import React, { useState, useEffect } from "react";
import { mdiClose, mdiDownload } from "@mdi/js";
import { useSelector } from "react-redux";
import Specs from "../../views/results/specs/Specs.js";
import Icon from "@mdi/react";
import products from "db/products.json";
import productsConfiguration from "db/products_configurations.json";
import { Fragment } from "react";
import logo from "assets/logo.svg";
import "./DetailProduct.scss";
import { Link } from "react-router-dom";
import { formatPlacca, getObjDifference } from "utils/products.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
// import formattedPdf from "./FormatPdf.js";
import { getBase64 } from "utils/base64.js";
import { printPdf } from "utils/printPdf.js";
import { replaceChar } from "utils/general.js";
// import formattedPdf from "./FormatPdf.js";
import Danger from "assets/icons/Danger.js";
pdfMake.addVirtualFileSystem(pdfFonts);

const DetailProduct = ({ product, propRef, close, catalogue }) => {
  const [base64, setBase64] = useState("");
  const [allResultsBase64, setAllResultsBase64] = useState([]);
  const results = useSelector((state) => state.results);
  const [imgBase64, setImgBase64] = useState("");
  const ref = React.createRef();
  // const title = product?.description?.split(" ").slice(0, 4).join(" ");
  const title = product?.name;
  const circles = useSelector(({ circles }) => circles);
  const spareParts = product.cap?.split(" ");
  spareParts?.shift();

  const formattedPlacca = formatPlacca(circles);

  const getCouplings = (product) => {
    const copy = { ...product };
    delete copy.brand;
    delete copy.cap;
    delete copy.code;
    delete copy.name;
    delete copy.description;
    delete copy.parking_station;
    delete copy.series;
    delete copy.total_lines;
    delete copy.picture;
    delete copy.drawing;
    delete copy.note_on_version;
    delete copy.max_resistance;
    return copy;
  };
  // console.log("results", results);
  const dbProduct = products.find((prod) => prod.code === product.code);
  const databaseCouplings = getCouplings(dbProduct);
  const couplings = getCouplings(product);

  const getConfigurationQuantity = (name, formattedPlacca) => {
    let quantity = 0;
    formattedPlacca.map((circle) => {
      const number = Object.keys(circle).filter((code) => code === name).length;
      quantity += number;
    });
    return quantity;
  };

  const getProductType = (p) => {
    const descriptions = (p || product).name?.split(" ");
    return descriptions[0] + " " + descriptions[1];
  };
  const type = getProductType()?.split(" ")[1];
  const parkingStation = product.parking_station?.split(" ");
  parkingStation && parkingStation.shift();

  const filteredCouplings =
    catalogue || product?.name?.includes("COMPLETE")
      ? Object.entries(databaseCouplings).filter(
          ([key, value]) => typeof value === "number"
        )
      : Object.entries(getObjDifference(product, dbProduct))
          .map(([key, value]) => {
            return [key, getConfigurationQuantity(key, formattedPlacca)];
          })
          .filter(([key, value]) => value > 0 && typeof value === "number");

  const image = () => {
    let image;
    try {
      image = require("assets/jpeg/" + product.picture + ".jpg");
      return image.default;
    } catch (e) {
      console.log("err image not found", e);
    }
  };

  const imageToBase64 = () => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", image(), true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
      // console.log("this response", this.response);
      var reader = new FileReader();
      reader.onload = function (event) {
        var res = event.target.result;

        setImgBase64(res);
      };
      var file = this.response;
      reader.readAsDataURL(file);
    };
    xhr.send();
  };

  const getAttachments = async (results) =>
    Promise.all(
      Object.entries(results)
        .map(([key, value]) =>
          Promise.all(
            Object.values(value).map((arrs) =>
              Promise.all(
                arrs.map(async (product, i) => {
                  let base64;
                  if (!!product.couple) {
                    base64 = await getBase64(
                      catalogue,
                      circles,
                      product.couple[0],
                      getProductType(product.couple[0])?.split(" ")[1],
                      filteredCouplings,
                      "printPage",
                      imgBase64,
                      formattedPlacca
                    );
                  } else {
                    base64 = await getBase64(
                      catalogue,
                      circles,
                      product,
                      getProductType(product)?.split(" ")[1],
                      filteredCouplings,
                      "printPage",
                      imgBase64,
                      formattedPlacca
                    );
                  }
                  // console.log("b64", base64);
                  // console.log("product", product);
                  return { filename: `${key}_${i + 1}`, base64 };
                })
              )
            )
          )
        )
        .flat(2)
    );

  const setAttachments = async () => {
    const selectedAttachment = await getBase64(
      catalogue,
      circles,
      product,
      getProductType(product).split(" ")[1],
      filteredCouplings,
      "printPage",
      imgBase64,
      formattedPlacca
    );
    setBase64(selectedAttachment);
    // if (!catalogue) {
    //   const attachments = await getAttachments(results);
    //   setAllResultsBase64(attachments);
    // }
  };

  useEffect(() => {
    // setAttachments();
    imageToBase64();
  }, []);

  // WARNINGS;

  useEffect(() => {
    // if (!!imgBase64) {
    setAttachments();

    // }
  }, [imgBase64]);

  const splitAndShift = (string) => {
    return string.split(" ").slice(1).join(" ");
  };

  const getFixedMobile = () => {
    const code = product.name
      .split("MULTI")
      .join("")
      .split("COMPLETE")
      .join("")
      .split("FIX")
      .join("")
      .split("MOBILE")
      .join("")
      .split("- CAP")
      .join("")
      .trim();

    // products
    const matching = products.filter(
      (prod) =>
        prod.name.includes(code) &&
        (prod.name.includes("FIX") || prod.name.includes("MOBILE"))
    );

    return matching;
  };

  const plates = getFixedMobile();
  // console.log("plates", plates);
  const opposite =
    plates.filter((plate) => plate.code !== product.code).length &&
    plates.filter((plate) => plate.code !== product.code)[0];
  // console.log("opposite", opposite);

  const getPdf = (product) => {
    let pdf;
    try {
      pdf = require("assets/pdf/" + product.drawing + ".pdf");
      if (pdf) {
        return pdf.default;
      }
    } catch (e) {
      console.log("err pdf not found", e);
    }
  };

  const getRelatedEmpty = () => {
    const code = product.name
      .split("MULTI")
      .join("")
      .split("COMPLETE")
      .join("")
      .split("FIX")
      .join("")
      .split("MOBILE")
      .join("")
      .split("EMPTY")
      .join("")
      .split("- CAP")
      .join("")
      .trim();

    // products
    const matching = products.find(
      (prod) => prod.name.includes(code) && prod.name.includes("COMPLETE")
    );

    return matching;
  };

  // const relatedEmpty = getRelatedEmpty();
  // console.log("related empty", relatedEmpty);

  const getRelatedEmpties = () => {
    const code = product.name
      .split("MULTI")
      .join("")
      .split("COMPLETE")
      .join("")
      .split("FIX")
      .join("")
      .split("MOBILE")
      .join("")
      .split("EMPTY")
      .join("")
      .split("- CAP")
      .join("")
      .trim();

    // console.log("code", code);
    // products
    const matching = products.filter(
      (prod) => prod.name.includes(code) && prod.name.includes("EMPTY")
    );

    return matching;
  };

  const relatedEmpties = getRelatedEmpties();
  // console.log("related empties", relatedEmpties);

  return (
    <div className="detail-product-component">
      <div className="pop-up-product" ref={propRef}>
        <Icon
          className="close-popup"
          path={mdiClose}
          size={1}
          title="Close"
          onClick={close}
        />

        <div className="pop-up__header">
          <div className="result-title no-mar">{title}</div>

          <img src={logo} alt="Stucchi" />
        </div>
        <div className="pop-up__body" ref={ref}>
          {!catalogue && (
            <div className="intro__single-result-spec">
              <h2 className="result-title" style={{ marginBottom: 0 }}>
                YOUR LINES CONFIGURATION
              </h2>
              {circles.find((value) => value?.type === "hydraulic") && (
                <Specs circles={circles} type="hydraulic" />
              )}

              {circles.find((value) => value?.type === "electric") && (
                <Specs circles={circles} type="electric" />
              )}
            </div>
          )}

          {image() && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img className="result-img no-event" src={image()} alt=""></img>
              <div
                style={{
                  display: "flex",
                  // justifyContent: "flex-end",
                  alignSelf: "flex-end",
                  marginTop: 0,
                }}
              >
                <div>
                  *The image is for illustrative purposes only, please refer to
                  the drawing
                </div>
              </div>
            </div>
          )}

          {/* <a
            href={pdf.default}
            target="_blank"
            rel="noreferrer"
            // download={`drawing ${product.drawing}`}
            className="btn outline center"
          >
            drawing pdf
          </a> */}

          {(type === "COMPLETE" || type === "FIX" || type === "MOBILE") &&
            (!product.name.includes("EMPTY") || catalogue) && (
              <>
                <div className="table-title">selected product</div>
                <table className="detail-product-table">
                  <thead>
                    <tr>
                      <th>code</th>
                      <th>name</th>
                      <th>description</th>
                      {/* <th>drawing</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        {product.code}
                        <p
                          style={{ marginTop: 4, fontSize: "0.8rem" }}
                        >{`Max operating resistance is ${product.max_resistance}`}</p>
                      </td>
                      <td>{product.name}</td>
                      <td>{product.description}</td>
                      {/* <td style={{ textAlign: "center" }}>
                        <a
                          href={getPdf(product)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Icon
                            className="download-icon"
                            path={mdiDownload}
                            title="Download Drawing"
                            size={0.9}
                            onClick={close}
                          />
                        </a>
                      </td> */}
                    </tr>
                  </tbody>
                </table>
                {/* <p
                  style={{ marginTop: 4, fontSize: "0.9rem" }}
                >{`Max operating resistance is ${product.max_resistance}`}</p> */}

                <Warning
                  product={product}
                  base64={base64}
                  allResultsBase64={allResultsBase64}
                  catalogue={catalogue}
                />

                {!product.name.includes("EMPTY") && (
                  <>
                    <div className="table-title">Components included</div>
                    <table className="detail-product-table">
                      <thead>
                        <tr>
                          <th>code</th>
                          <th>quantity</th>
                          <th>description</th>
                          {/* <th>drawing</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {products
                          .filter((prod) => {
                            if (type !== "COMPLETE") {
                              return (
                                prod.picture === product.picture &&
                                prod.name.includes("EMPTY") &&
                                prod.name.includes(
                                  type === "FIX" ? "FIX" : "MOBILE"
                                )
                              );
                            } else {
                              if (!product.name.includes("V0")) {
                                return (
                                  prod.picture === product.picture &&
                                  prod.name.includes("EMPTY") &&
                                  !prod.name.includes("V0")
                                );
                              } else {
                                return (
                                  prod.picture === product.picture &&
                                  prod.name.includes("EMPTY")
                                );
                              }
                            }
                          })
                          .map((component, i) => {
                            return (
                              <Fragment key={i}>
                                <tr>
                                  <td style={{ textAlign: "center" }}>
                                    {component.code}
                                  </td>
                                  <td style={{ textAlign: "center" }}>1</td>
                                  <td> {component.name}</td>
                                  {/* <td style={{ textAlign: "center" }}>
                                    <a
                                      href={getPdf(product)}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Icon
                                        className="download-icon"
                                        path={mdiDownload}
                                        title="Download Drawing"
                                        size={0.9}
                                        onClick={close}
                                      />
                                    </a>
                                  </td> */}
                                </tr>
                              </Fragment>
                            );
                          })}

                        {filteredCouplings.map((coup, i) => {
                          const matched = productsConfiguration.find(
                            ({ code }) => code === coup[0]
                          );

                          return (
                            <Fragment key={i}>
                              {(type === "COMPLETE" || type === "MOBILE") && (
                                <tr>
                                  <td style={{ textAlign: "center" }}>
                                    {matched && matched.fixed}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {coup[1]}
                                  </td>
                                  <td>{"F " + coup[0]}</td>
                                  {/* <td></td> */}
                                </tr>
                              )}

                              {(type === "COMPLETE" || type === "FIX") && (
                                <tr>
                                  <td style={{ textAlign: "center" }}>
                                    {matched && matched.mobile}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {coup[1]}
                                  </td>
                                  <td>{"M " + coup[0]}</td>
                                  {/* <td></td> */}
                                </tr>
                              )}
                            </Fragment>
                          );
                        })}

                        {product.cap && (
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              {product.cap?.split(" ")[0]}
                            </td>
                            <td style={{ textAlign: "center" }}>1</td>
                            <td>{spareParts.join(" ")}</td>
                            {/* <td style={{ textAlign: "center" }}>
                              <a
                                href={getPdf(product)}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon
                                  className="download-icon"
                                  path={mdiDownload}
                                  title="Download Drawing"
                                  size={0.9}
                                  onClick={close}
                                />
                              </a>
                            </td> */}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </>
                )}

                {type === "COMPLETE" &&
                  products.filter(
                    (prod) =>
                      prod.description === product.description?.split(" -")[0]
                  ).length > 0 && (
                    <>
                      <div className="table-title">Related Products</div>
                      <table className="detail-product-table">
                        <thead>
                          <tr>
                            <th>code</th>
                            <th>quantity</th>
                            <th>description</th>
                            {/* <th>drawing</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {products
                            .filter((prod) => {
                              const mobileCode = replaceChar(
                                product.code,
                                "1",
                                -3
                              );

                              const fixCode = replaceChar(
                                product.code,
                                "2",
                                -3
                              );

                              return (
                                prod.code === mobileCode ||
                                prod.code === fixCode
                              );
                            })
                            .map((component, i) => {
                              return (
                                <Fragment key={i}>
                                  <tr>
                                    <td style={{ textAlign: "center" }}>
                                      {component.code}
                                    </td>
                                    <td style={{ textAlign: "center" }}>1</td>
                                    <td> {component.name}</td>
                                    {/* <td style={{ textAlign: "center" }}>
                                      <a
                                        href={getPdf(product)}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <Icon
                                          className="download-icon"
                                          path={mdiDownload}
                                          title="Download Drawing"
                                          size={0.9}
                                          onClick={close}
                                        />
                                      </a>
                                    </td> */}
                                  </tr>
                                </Fragment>
                              );
                            })}
                        </tbody>
                      </table>
                    </>
                  )}

                {(type === "MOBILE" || type === "FIX") && (
                  <>
                    <div className="table-title">Related Products</div>
                    <table className="detail-product-table">
                      <thead>
                        <tr>
                          <th>code</th>
                          <th>quantity</th>
                          <th>description</th>
                          {/* <th>drawing</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {products
                          .filter(
                            (prod) =>
                              prod.picture === product.picture &&
                              prod.name.includes("EMPTY") &&
                              prod.name.includes(
                                type !== "FIX" ? "FIX" : "MOBILE"
                              )
                          )
                          .map((component, i) => {
                            return (
                              <Fragment key={i}>
                                <tr>
                                  <td style={{ textAlign: "center" }}>
                                    {component.code}
                                  </td>
                                  <td style={{ textAlign: "center" }}>1</td>
                                  <td> {component.name}</td>
                                  {/* <td style={{ textAlign: "center" }}>
                                    <a
                                      href={getPdf(product)}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Icon
                                        className="download-icon"
                                        path={mdiDownload}
                                        title="Download Drawing"
                                        size={0.9}
                                        onClick={close}
                                      />
                                    </a>
                                  </td> */}
                                </tr>
                              </Fragment>
                            );
                          })}

                        {filteredCouplings.map((coup, i) => {
                          const matched = productsConfiguration.find(
                            ({ code }) => code === coup[0]
                          );

                          return (
                            <Fragment key={i}>
                              {type !== "MOBILE" &&
                                !product.name.includes("EMPTY") && (
                                  <tr>
                                    <td style={{ textAlign: "center" }}>
                                      {matched && matched.fixed}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {coup[1]}
                                    </td>
                                    <td>{"F " + coup[0]}</td>
                                    {/* <td></td> */}
                                  </tr>
                                )}

                              {type !== "FIX" &&
                                !product.name.includes("EMPTY") && (
                                  <tr>
                                    <td style={{ textAlign: "center" }}>
                                      {matched && matched.mobile}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {coup[1]}
                                    </td>
                                    <td>{"M " + coup[0]}</td>
                                    {/* <td></td> */}
                                  </tr>
                                )}
                            </Fragment>
                          );
                        })}

                        {product.cap && type !== "FIX" && (
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              {product.cap?.split(" ")[0]}
                            </td>
                            <td style={{ textAlign: "center" }}>1</td>
                            <td>{spareParts.join(" ")}</td>
                            {/* <td style={{ textAlign: "center" }}>
                              <a
                                href={getPdf(product)}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon
                                  className="download-icon"
                                  path={mdiDownload}
                                  title="Download Drawing"
                                  size={0.9}
                                  onClick={close}
                                />
                              </a>
                            </td> */}
                          </tr>
                        )}
                      </tbody>
                    </table>

                    {product.parking_station && type !== "MOBILE" && (
                      <>
                        <div className="table-title">
                          Available upon request
                        </div>
                        <table className="detail-product-table">
                          <thead>
                            <tr>
                              <th>code</th>
                              <th>name</th>
                              {/* <th>drawing</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ textAlign: "center" }}>
                                {product.parking_station?.split(" ")[0]}
                              </td>
                              <td>{parkingStation.join(" ")}</td>
                              {/* <td style={{ textAlign: "center" }}>
                                <a
                                  href={getPdf(product)}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Icon
                                    className="download-icon"
                                    path={mdiDownload}
                                    title="Download Drawing"
                                    size={0.9}
                                    onClick={close}
                                  />
                                </a>
                              </td> */}
                            </tr>
                          </tbody>
                        </table>
                      </>
                    )}
                  </>
                )}

                {product.parking_station && (
                  <>
                    <div className="table-title">Available upon request</div>
                    <table className="detail-product-table">
                      <thead>
                        <tr>
                          <th>code</th>
                          <th>name</th>
                          {/* <th>drawing</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ textAlign: "center" }}>
                            {product.parking_station?.split(" ")[0]}
                          </td>
                          <td>{parkingStation.join(" ")}</td>
                          {/* <td style={{ textAlign: "center" }}>
                            <a
                              href={getPdf(product)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Icon
                                className="download-icon"
                                path={mdiDownload}
                                title="Download Drawing"
                                size={0.9}
                                onClick={close}
                              />
                            </a>
                          </td> */}
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
              </>
            )}

          {product.name.includes("EMPTY") && !catalogue && (
            <>
              <div className="table-title">Selected product</div>
              <table className="detail-product-table">
                <thead>
                  <tr>
                    <th>code</th>
                    <th>quantity</th>
                    <th>description</th>
                    {/* <th>drawing</th> */}
                  </tr>
                </thead>
                <tbody>
                  {relatedEmpties.map((component, i) => {
                    return (
                      <Fragment key={i}>
                        <tr>
                          <td style={{ textAlign: "center" }}>
                            {component.code}
                            <p
                              style={{ marginTop: 4, fontSize: "0.7rem" }}
                            >{`Max operating resistance is ${component.max_resistance}`}</p>
                          </td>
                          <td style={{ textAlign: "center" }}>1</td>
                          <td> {component.name}</td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
              <Warning
                product={product}
                base64={base64}
                allResultsBase64={allResultsBase64}
                catalogue={catalogue}
              />
              <div className="table-title">
                Related products (to be ordered separately)
              </div>
              <table className="detail-product-table">
                <thead>
                  <tr>
                    <th>code</th>
                    <th>quantity</th>
                    <th>description</th>
                    {/* <th>drawing</th> */}
                  </tr>
                </thead>
                <tbody>
                  {filteredCouplings
                    .filter((coup) => !coup[0].includes("EC7"))
                    .map((coup, i) => {
                      console.log("COUP", coup);
                      const matched = productsConfiguration.find(
                        ({ code }) => code === coup[0]
                      );

                      return (
                        <Fragment key={i}>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              {matched && matched.fixed}
                            </td>
                            <td style={{ textAlign: "center" }}>{coup[1]}</td>
                            {coup[0].includes("EC6") ? (
                              <td>{"F " + coup[0] + "*"}</td>
                            ) : (
                              <td>{"F " + coup[0]} </td>
                            )}
                          </tr>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              {matched && matched.mobile}
                            </td>
                            <td style={{ textAlign: "center" }}>{coup[1]}</td>
                            {coup[0].includes("EC6") ? (
                              <td>{"M " + coup[0] + "*"}</td>
                            ) : (
                              <td>{"M " + coup[0]} </td>
                            )}
                            {/* <td></td> */}
                          </tr>
                        </Fragment>
                      );
                    })}

                  {relatedEmpties
                    .filter((prod) => prod.cap)
                    .map((product, i) => {
                      return (
                        <Fragment key={i}>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              {product.cap?.split(" ")[0]}
                            </td>
                            <td style={{ textAlign: "center" }}>1</td>
                            <td>{splitAndShift(product.cap)}</td>
                            {/* <td style={{ textAlign: "center" }}>
                              <a
                                href={getPdf(product)}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon
                                  className="download-icon"
                                  path={mdiDownload}
                                  title="Download Drawing"
                                  size={1}
                                  onClick={close}
                                />
                              </a>
                            </td> */}
                          </tr>
                        </Fragment>
                      );
                    })}
                  {relatedEmpties
                    .filter((prod) => prod.parking_station)
                    .map((product, i) => {
                      return (
                        <Fragment key={i}>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              {product.parking_station?.split(" ")[0]}
                            </td>
                            <td style={{ textAlign: "center" }}>1</td>
                            <td>{splitAndShift(product.parking_station)}</td>
                          </tr>
                        </Fragment>
                      );
                    })}
                </tbody>
                {filteredCouplings.filter((coup) => coup[0].includes("EC7"))
                  .length > 0 && (
                  <div style={{ marginTop: 4 }}>
                    *Other EC with different poles available upon request
                  </div>
                )}
              </table>
            </>
          )}
        </div>
        <div className="btn-container">
          <div style={{ justifyContent: "flex-end" }}>
            <Link
              className="btn center"
              to={{
                pathname: "/form",
                state: { product, base64, allResultsBase64, catalogue },
              }}
            >
              request info
            </Link>
          </div>
          <div style={{ marginLeft: "1rem" }}>
            <div
              onClick={() =>
                printPdf(
                  catalogue,
                  circles,
                  product,
                  getProductType(product).split(" ")[1],
                  filteredCouplings,
                  "printPage",
                  imgBase64,
                  formattedPlacca
                )
              }
              className="btn outline"
            >
              print pdf
            </div>
          </div>
          <div style={{ marginLeft: "1rem" }}>
            <a
              className="btn outline danger"
              href="/stucchi_multicoupling_warnings_v2.pdf"
              target="_blank"
              onClick={() =>
                window.dataLayer.push({
                  event: "download_notice",
                })
              }
            >
              IMPORTANT NOTICE / INSTRUCTIONS
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailProduct;

const Warning = ({ product, base64, allResultsBase64, catalogue }) => (
  <div
    style={{
      background: "#e6007e22",
      fontSize: "1.2rem",
      padding: 24,
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      gap: 24,
    }}
  >
    <div>
      <Danger fill="#e6007e" />
    </div>
    <div>
      The force applied to multicoupling coupled depends on the number of
      couplings under pressure at the same time, on their operating pressure and
      on their size. For a correct use of the multicoupling it is necessary to
      verify that the force is not higher than the maximum resistance of the
      multicoupling.
      <br />
      <br />
      <b style={{ fontWeight: "bold" }}>
        Please{" "}
        <Link
          className="center"
          to={{
            pathname: "/form",
            state: {
              product,
              base64,
              allResultsBase64,
              catalogue,
            },
          }}
          style={{
            cursor: "pointer",
            color: "unset",
            textDecoration: "underline",
          }}
        >
          address to Stucchi Technical Service
        </Link>{" "}
        for more information.
      </b>
    </div>
  </div>
);
