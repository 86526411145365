import "./styles/general.scss";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Configurator from "views/configurator/Configurator";
import BottomBar from "components/bottom-bar/BottomBar";
import Results from "views/results/Results";
import Catalogue from "views/catalogue/Catalogue";
import Form from "views/form/Form";
import MyHelmet from "utils/helmet";

const App = () => (
  <BrowserRouter>
    <MyHelmet />
    <Switch>
      <Route exact path="/" component={Configurator} />
      <Route path="/catalogue" component={Catalogue} />
      <Route path="/results" component={Results} />
      <Route path="/form" component={Form} />
      <Redirect to="/" />
    </Switch>

    <BottomBar />
  </BrowserRouter>
);

export default App;
