import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLines, unsetOperatorMode, updateCircle } from "reducer";
import "./StepsConfig.scss";
import TooltipCode from "./tooltip-code/TooltipCode";

const StepsConfig = () => {
  const dispatch = useDispatch();
  const [isOperator, setIsOperator] = useState(
    localStorage.getItem("operator_mode")
  );
  const isOperatorLocal = useSelector(({ operator_mode }) => operator_mode);
  const isHydraulicSet = useSelector(
    ({ is_hydraulic_set }) => is_hydraulic_set
  );
  const isElectricSet = useSelector(({ is_electric_set }) => is_electric_set);
  const hydraulicLines = useSelector(({ lines }) => lines.hydraulic);
  const electricLines = useSelector(({ lines }) => lines.electric);
  const almostLines = hydraulicLines > 0 || electricLines > 0;
  const circles = useSelector(({ circles }) => circles);
  const hydraulicCircles = circles.filter(
    (circle) => circle?.type === "hydraulic" && circle.setting
  ).length;
  const electricCircles = circles.filter(
    (circle) => circle?.type === "electric" && circle.setting
  ).length;

  useEffect(() => {
    if (!isOperator) {
      setIsOperator(isOperatorLocal);
    }
  }, [isOperatorLocal]);

  const resetStep1 = () => {
    dispatch(setLines("hydraulic", null));
  };

  const resetStep2 = () => dispatch(setLines("electric", null));

  const resetStep3 = () => {
    circles
      .filter((circle) => circle?.type === "hydraulic")
      .forEach((circle) => {
        dispatch(updateCircle(circle.id));
      });
    circles
      .filter((circle) => circle?.type === "electric")
      .forEach((circle) => {
        dispatch(updateCircle(circle.id));
      });
  };

  // const resetStep4 = () => {
  //   circles
  //     .filter((circle) => circle?.type === "electric")
  //     .forEach((circle) => {
  //       dispatch(updateCircle(circle.id));
  //     });
  // };

  const steps = [
    {
      index: 1,
      text: "Select Hydraulic Lines",
      isCompleted: isHydraulicSet || hydraulicLines > 0,
      reset: resetStep1,
    },
    {
      index: 2,
      text: "Select Electric Lines",
      isCompleted: isElectricSet && almostLines,
      reset: resetStep2,
    },
    {
      index: 3,
      text: "Configure Hydraulic Lines",
      isCompleted:
        almostLines && isHydraulicSet && hydraulicCircles === hydraulicLines,
      reset: resetStep3,
    },
    // {
    //   index: 4,
    //   text: "Configure Electric Lines",
    //   isCompleted:
    //     almostLines &&
    //     isHydraulicSet &&
    //     isElectricSet &&
    //     hydraulicCircles === hydraulicLines &&
    //     electricCircles === electricLines,
    //   reset: resetStep4,
    // },
    {
      index: 5,
      text: "Done. Click the GO Button",
      isCompleted:
        almostLines &&
        isHydraulicSet &&
        // isElectricSet &&
        hydraulicCircles === hydraulicLines,
      //  &&
      // electricCircles === electricLines,
    },
  ];

  const activeStep =
    steps.find((element) => !element.isCompleted) || steps[steps.length - 1];

  const [tooltip, setTooltip] = useState(false);

  return (
    <>
      {isOperator && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingBottom: "1rem",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              fontSize: "1.8rem",
              color: " var(--pink)",
              fontWeight: 500,
            }}
          >
            Operator Mode
          </div>
          <div
            className="btn"
            style={{ marginLeft: "12px" }}
            onClick={() => {
              // localStorage.removeItem("operator_mode");
              dispatch(unsetOperatorMode());
            }}
          >
            ESCI
          </div>
        </div>
      )}
      <div className="configurator__steps-config">
        <TooltipCode />

        <div className="steps">
          {steps.map((element, i) => (
            <div
              className="step"
              key={i}
              onClick={element.isCompleted ? element.reset : null}
              onMouseEnter={element.index === 5 ? null : () => setTooltip(i)}
              onMouseLeave={() => setTooltip(false)}
            >
              <div
                key={i}
                className={`step__dot ${element.isCompleted ? "active" : ""} ${
                  element.index === 5 ? "" : "hover"
                }`}
              >
                {tooltip === i && (
                  <div className="tooltip-step">
                    {element.isCompleted ? "Back to" : ""} "{element.text}"
                  </div>
                )}
              </div>
              <div
                className={`step__text ${
                  element.index === activeStep.index ? "active" : ""
                }`}
              >
                {`${element.index}. ${element.text}`}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default StepsConfig;
