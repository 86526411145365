import { mdiCheck, mdiRestore } from "@mdi/js";
import Icon from "@mdi/react";
import linesConfigurationOptions from "db/line_configuration_options.json";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCircle } from "reducer/index";
import "./ConfigurationPanel.scss";
import SelectConfig from "./select-config/SelectConfig";
import SelectCopy from "./select-copy/SelectCopy";
import { isEqual } from "lodash";

const ConfigurationPanel = ({ activeCircle, setActiveCircle }) => {
  const dispatch = useDispatch();
  const selects = linesConfigurationOptions["hydraulic"];
  const circles = useSelector(({ circles }) => circles);
  const [tempConfig, setTempConfig] = useState({});

  // console.log("select", selects);
  // console.log("tempConfig", tempConfig);
  // const [repeatTarget, setRepeatTarget] = useState(null);
  // const hydraulicLines = circles.filter(
  //   (circle) => circle?.type === "hydraulic"
  // ).length;

  // const otherLines = circles
  //   .filter(
  //     (circle) =>
  //       !!circle &&
  //       circle?.type === activeCircle.type &&
  //       circle?.id !== activeCircle.id &&
  //       circle.setting
  //   )
  //   .map((circle) =>
  //     circle.type === "electric" ? circle.index + hydraulicLines : circle.index
  //   );

  const isAlreadySetted = activeCircle.every((c) => !!c.setting);
  const isDisabled = () => {
    const isEveryOptionFilled = selects
      .map((select) => tempConfig.setting && !!tempConfig.setting[select.id])
      .every((select) => !!select);

    return !isEveryOptionFilled && !isAlreadySetted;
  };

  // console.log("isDisabled", isDisabled());

  const isCompleted = activeCircle.setting;
  // useEffect(() => {
  //   setRepeatTarget(otherLines[0]);
  // }, [activeCircle]);

  useEffect(() => setTempConfig(activeCircle), [activeCircle]);

  const handleSubmit = () => {
    activeCircle.map((circle) => {
      dispatch(updateCircle(circle.id, tempConfig.setting));
    });
    setActiveCircle([]);
  };

  // const handleCopy = () => {
  //   const setting = circles.find(
  //     (element) =>
  //       element.type === activeCircle.type &&
  //       element.index ===
  //         repeatTarget - (element.type === "electric" && hydraulicLines)
  //   ).setting;

  //   const newConfig = {
  //     ...tempConfig,
  //     setting,
  //   };

  //   setTempConfig(newConfig);
  // };

  const setActiveConfig = (target, value, extra) => {
    const newConfig = {
      ...tempConfig,
      setting: { ...tempConfig.setting, [target]: value },
    };
    if (extra) {
      newConfig.setting = {
        ...newConfig.setting,
        [extra.target]: {
          ...extra.data,
          reference: {
            target: target,
            data: {
              value: value.value,
              label: value.label,
            },
          },
        },
      };
    }
    setTempConfig(newConfig);
  };

  const resetActiveConfig = (target, extra) => {
    const newConfig = {
      ...tempConfig,
      setting: { ...tempConfig?.setting, [target]: null },
    };
    if (extra) {
      newConfig.setting = {
        ...newConfig.setting,
        [extra.target]: null,
      };
    }
    setTempConfig(newConfig);
  };

  const getValue = (select) => {
    // controllo se i cerchi attivi sono 1 o più

    if (activeCircle.length === 1) {
      // prendo configurtazioni corrispondenti
      const circle = circles.find(
        (circle) => circle?.id === activeCircle[0].id
      );

      if (select.id === "port") {
        return (
          (tempConfig.setting &&
            tempConfig.setting[select.id] &&
            `${tempConfig.setting[select.id].value}"`) ||
          (circle.setting &&
            circle.setting[select.id] &&
            `${circle.setting[select.id].value}"`)
        );
      }
      return (
        (tempConfig.setting &&
          tempConfig.setting[select.id] &&
          tempConfig.setting[select.id].label) ||
        (circle.setting &&
          circle.setting[select.id] &&
          circle.setting[select.id].label)
      );
    } else {
      //vedo se tutti i cerchi attivi hanno configurazione
      const hasSetting = activeCircle.every((activeCircle) => {
        return circles.some(
          (circle) => circle?.id === activeCircle.id && circle.setting
        );
      });

      //prendo configurazioni e controllo se sono uguali
      const activeCirclesConfig = activeCircle.filter((activeCircle) => {
        return circles.some(
          (circle) => circle?.id === activeCircle.id && circle.setting
        );
      });
      const isConfigEqual = activeCirclesConfig.every((activeCircle) => {
        return activeCircle.setting === activeCirclesConfig[0].setting;
      });

      if (hasSetting && activeCirclesConfig && isConfigEqual) {
        if (select.id === "port") {
          return (
            (tempConfig.setting &&
              tempConfig.setting[select.id] &&
              `${tempConfig.setting[select.id].value}"`) ||
            (circles[0].setting &&
              circles[0].setting[select.id] &&
              `${circles[0].setting[select.id].value}"`)
          );
        }
        return (
          (tempConfig.setting &&
            tempConfig.setting[select.id] &&
            tempConfig.setting[select.id].label) ||
          (circles[0].setting &&
            circles[0].setting[select.id] &&
            circles[0].setting[select.id].label)
        );
      }
      // configurazioni diverse o non presenti
      if (select.id === "port") {
        return (
          tempConfig.setting &&
          tempConfig.setting[select.id] &&
          `${tempConfig.setting[select.id].value}"`
        );
      }
      return (
        tempConfig.setting &&
        tempConfig.setting[select.id] &&
        tempConfig.setting[select.id].label
      );
    }
  };

  const handleReset = () => dispatch(updateCircle(activeCircle.id));
  // const capitalizeFirst = (text) =>
  //   text[0].toUpperCase() + text.slice(1).toLowerCase();
  return (
    <div className="configurator__panel">
      <div className="title">
        {/* <h3 > */}
        Hydraulic Lines
        {/* {capitalizeFirst(activeCircle.type)} line {activeCircle.index} */}
        {/* </h3> */}
      </div>
      <div className="configuration">
        <span> CONFIGURATION </span>
        {isCompleted && (
          <Icon
            path={mdiRestore}
            size={1}
            style={{ marginLeft: 12 }}
            onClick={handleReset}
            title="Reset"
          />
        )}
      </div>
      <div className="configuration-numbers">
        {activeCircle.map((circle, i) => {
          return (
            <div key={i} className="configuration-number">
              <span>{circle?.index}</span>
            </div>
          );
        })}
      </div>

      <div className="configurator-pane__form">
        {selects.map((select, i) => {
          return (
            <SelectConfig
              key={`select_${i}`}
              value={getValue(select)}
              target={select.id}
              options={select.options}
              isAlreadySetted={isAlreadySetted}
              setActiveConfig={setActiveConfig}
              resetActiveConfig={resetActiveConfig}
              // activeCircle={activeCircle}
              tempConfig={tempConfig}
              type={activeCircle[0].type}
            />
          );
        })}

        {/* {!isModified && ( */}
        <div
          onClick={handleSubmit}
          className={`btn ${isDisabled() ? "disabled" : ""} ${
            isCompleted ? "modified" : ""
          }`}
        >
          <span>{isCompleted ? "edit" : "confirm"}</span>
          <Icon path={mdiCheck} size={1} />
        </div>
        {/* )} */}

        {/* {!isCompleted && repeatTarget && (
          <SelectCopy
            value={repeatTarget}
            options={otherLines}
            onChange={setRepeatTarget}
            handleCopy={handleCopy}
          />
        )} */}
        <div className="btn-tooltip">
          Configure Hydraulic Lines (One or More)
        </div>
      </div>
    </div>
  );
};

export default ConfigurationPanel;
