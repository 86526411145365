import { useState, useEffect, useRef } from "react";

const useComponentVisible = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const open = (item) => {
    document.body.classList.add("no-scroll");
    setIsComponentVisible(item);
  };

  const close = () => {
    document.body.classList.remove("no-scroll");
    setIsComponentVisible(false);
  };

  const toggling = () => {
    document.body.classList.toggle("no-scroll");
    setIsComponentVisible((state) => !state);
  };

  const handleHideDropdown = ({ key }) => {
    if (key === "Escape") {
      close();
    }
  };

  const handleClickOutside = ({ target }) => {
    if (ref.current && !ref.current.contains(target)) {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return {
    ref,
    isComponentVisible,
    setIsComponentVisible,
    toggling,
    close,
    open,
  };
};

export default useComponentVisible;
