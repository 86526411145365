export const formatLabelSpecs = (label) => {
  if (label === "body_size") {
    return "body size";
  }
  if (label === "litre") {
    return "flow rate";
  }
  return label;
};

export const formatResultsSpecs = (element, key, electric) => {
  if (!electric) {
    if (key.id === "body_size" && !electric) {
      return element.setting[key.id]?.label;
    }
    if (key.id === "body_size" && electric) {
      return element.setting[key.id]?.value + '"';
    }
    if (key.id === "litre") {
      return element.setting[key.id]?.value + " l/min";
    }
    if (key.id === "port") {
      return element.setting[key.id]?.value + '"';
    }
    return element.setting[key.id]?.value;
  }
};

export const noSpace = (string) => {
  return string.split(" ").join("_");
};

export const replaceChar = (origString, replaceChar, index) => {
  let string = origString.toString();
  let firstPart = string.substr(0, string.length + index);
  let lastPart = string.substr(index + 1);

  let newString = firstPart + replaceChar + lastPart;
  return parseInt(newString);
};
