const configurationsTestList = [
  { productCode: 870007015, configurationCode: "CH0002CH0002" },
  { productCode: 870007016, configurationCode: "CH0001CH0001" },
  { productCode: 870008022, configurationCode: "CH0002CH0002" },
  { productCode: 870008016, configurationCode: "CH1104CH1104" },
  { productCode: 870008019, configurationCode: "CH1102CH1102" },
  { productCode: 870008012, configurationCode: "CH1102CH1102" },
  { productCode: 870008018, configurationCode: "CH2204CH2204" },
  { productCode: 870008029, configurationCode: "CH3304CH3304" },
  { productCode: 870008017, configurationCode: "CH3305CH3305" },
  { productCode: 870009014, configurationCode: "CH0001CH1102" },
  { productCode: 870009015, configurationCode: "CH0001CH1102CE11" },
  { productCode: 870008027, configurationCode: "CH0001CH2204CH2204" },
  { productCode: 870015056, configurationCode: "CH2204CH4405CH4405" },
  { productCode: 870015088, configurationCode: "CH2224CH4426CH4426" },
  { productCode: 870009016, configurationCode: "CH0001CH0001CE11" },
  { productCode: 870011017, configurationCode: "CH0001CH0001CH0001CH0001" },
  { productCode: 870011020, configurationCode: "CH0002CH0002CH0002CH0002" },
  { productCode: 870011022, configurationCode: "CH0000CH0000CH0000CH0000" },
  { productCode: 870011028, configurationCode: "CH0021CH0021CH0021CH0021" },
  { productCode: 870011029, configurationCode: "CH0012CH0012CH0012CH0012" },
  { productCode: 870012007, configurationCode: "CH1104CH1104CH1104CH1104" },
  { productCode: 870012008, configurationCode: "CH1102CH1102CH1102CH1102" },
  { productCode: 870012009, configurationCode: "CH1112CH1112CH1112CH1112" },
  // { productCode: 870011021, configurationCode: "CH0001CH0001CH0002CH0002" },
  {
    productCode: 870008023,
    configurationCode: "CH0001CH0001CH3305CH3305CH3305CH3305",
  },
  { productCode: 870001040, configurationCode: "CH0001CH0001CH0002" },
  { productCode: 870008028, configurationCode: "CH3305CH3305CH3305CH3305" },
  { productCode: 870001043, configurationCode: "CH0001CH0001CH0001" },
  { productCode: 870001044, configurationCode: "CH0002CH0002CH0002" },
  { productCode: 870003017, configurationCode: "CH1102CH1104CH1104" },
  { productCode: 870003020, configurationCode: "CH1102CH1102CH1102" },
  { productCode: 870003021, configurationCode: "CH1104CH1104CH1104" },
  { productCode: 870015075, configurationCode: "CH0001CH4405CH4405" },
  { productCode: 870015083, configurationCode: "CH0001CH4406CH4406" },
  { productCode: 870016052, configurationCode: "CH0002CH4405CH4405" },
  { productCode: 870016001, configurationCode: "CH4406CH4406" },
  { productCode: 870015065, configurationCode: "CH1102CH1102CH2204" },
  { productCode: 870015091, configurationCode: "CH1102CH2204" },
  { productCode: 870016014, configurationCode: "CH0001CH0001CH1102CH1102" },
  { productCode: 870016015, configurationCode: "CH0001CH0001CH1102CH3305" },
  { productCode: 870015058, configurationCode: "CH0001CH0001CH2204CH3305" },
  { productCode: 870015060, configurationCode: "CH0001CH2204CH3305" },
  { productCode: 870015074, configurationCode: "CH2204CH3305" },
  { productCode: 870015086, configurationCode: "CH2204CH3305CE11CE11" },
  { productCode: 870016025, configurationCode: "CH3304CE11" },
  { productCode: 870016026, configurationCode: "CH0002CH0002CH3305" },
  { productCode: 870015095, configurationCode: "CH0001CH0001CH2204" },
  { productCode: 870016008, configurationCode: "CH0002CH2204CH2204CE11" },
  { productCode: 870016041, configurationCode: "CH0011CH2214CH2214" },
  { productCode: 870016060, configurationCode: "CH0021CH2224CH2224" },
  {
    productCode: 870015080,
    configurationCode: "CH1102CH1102CH3304CH3304CH3304",
  },
  { productCode: 870016033, configurationCode: "CH1104CH1104CH3305" },
  { productCode: 870016046, configurationCode: "CH1104CH3305CH3305CH3305" },
  { productCode: 870015066, configurationCode: "CH0001CH0001CH3305CH4406" },
  { productCode: 870015079, configurationCode: "CH0001CH3304CH4405CE11" },
  { productCode: 870015084, configurationCode: "CH3304CH4405CE11CE11" },
  { productCode: 870015099, configurationCode: "CH0001CH0001CH3304CH4405" },
  {
    productCode: 870016000,
    configurationCode: "CH0002CH0002CH0002CH3305CH4406",
  },
  { productCode: 870016030, configurationCode: "CH0001CH0002CH3305CH4406CE11" },
  { productCode: 870016042, configurationCode: "CH0001CH3305CH4406" },
  { productCode: 870016043, configurationCode: "CH0001CH0001CH4406" },
  { productCode: 870016051, configurationCode: "CH1102CH1102CH2204CH4405" },
  {
    productCode: 870015063,
    configurationCode: "CH1102CH1102CH2204CH2204CH4406",
  },
  { productCode: 870015097, configurationCode: "CH2204CH2204CH4405" },
  { productCode: 870016003, configurationCode: "CH2204CH2204CH4406" },
  { productCode: 870016012, configurationCode: "CH1102CH1102CH2204CH2204" },
  {
    productCode: 870016010,
    configurationCode: "CH2204CH2204CH2204CH2204CH2204",
  },
  {
    productCode: 870000074,
    configurationCode: "CH0001CH0001CH0001CH0001CH0001",
  },
  {
    productCode: 870000075,
    configurationCode: "CH0002CH0002CH0002CH0002CH0002CH0002",
  },
  {
    productCode: 870000079,
    configurationCode: "CH0001CH0001CH0001CH0001CH0001CH0001",
  },
  { productCode: 870000080, configurationCode: "CH0002CH0002CH0002CH0002" },
  { productCode: 870000086, configurationCode: "CH0012CH0012CH0012CH0012CE11" },
  {
    productCode: 870015096,
    configurationCode: "CH0000CH0000CH0000CH0000CH0000",
  },
  {
    productCode: 870015064,
    configurationCode: "CH0002CH0002CH0002CH0002CH1104CH1104",
  },
  {
    productCode: 870016002,
    configurationCode: "CH0001CH0001CH0001CH0001CH1104CH1104",
  },
  { productCode: 870016032, configurationCode: "CH0001CH0001CH1102CH1102" },
  {
    productCode: 870016034,
    configurationCode: "CH0001CH0001CH1102CH1102CE11CE11",
  },
  {
    productCode: 870016013,
    configurationCode: "CH0000CH0000CH0000CH0000CH1102",
  },
  {
    productCode: 870015073,
    configurationCode: "CH1102CH1102CH1102CH1102CH3304CH3304",
  },
  {
    productCode: 870016031,
    configurationCode: "CH1102CH1102CH3305CH3305CE11CE11",
  },
  {
    productCode: 870016036,
    configurationCode: "CH1102CH1102CH1104CH1104CH3304CH3305",
  },
  {
    productCode: 870016016,
    configurationCode: "CH0001CH0001CH1102CH1102CH2204CH2204",
  },
  {
    productCode: 870016028,
    configurationCode: "CH0001CH1102CH1102CH2204CH2204",
  },
  {
    productCode: 870002030,
    configurationCode: "CH1104CH1104CH1104CH1104CH1104CH1104",
  },
  {
    productCode: 870002031,
    configurationCode: "CH1102CH1102CH1102CH1102CH1102CH1102",
  },
  {
    productCode: 870016017,
    configurationCode: "CH0001CH0001CH0001CH1102CH2204CH3305",
  },
  {
    productCode: 870015062,
    configurationCode: "CH0001CH2204CH2204CH3305CH3305",
  },
  {
    productCode: 870015092,
    configurationCode: "CH0001CH0001CH2204CH2204CH3305CH3305",
  },
  {
    productCode: 870015094,
    configurationCode: "CH0001CH0001CH2204CH2204CH3305",
  },
  { productCode: 870015098, configurationCode: "CH0001CH0001CH3305CH3305" },
  {
    productCode: 870015059,
    configurationCode: "CH0001CH1102CH1102CH4406CH3305",
  },

  { productCode: 870015093, configurationCode: "CH0001CH1102CH1102CH3304" },
  {
    productCode: 870016004,
    configurationCode: "CH1102CH3305CH3305CH3305CH3305",
  },
  {
    productCode: 870016038,
    configurationCode: "CH1104CH1104CH3305CH3305CH3305CH3305",
  },
  {
    productCode: 870016019,
    configurationCode: "CH0001CH0001CH2204CH2204CH2204CH2204",
  },
  { productCode: 870015076, configurationCode: "CH0002CH3305CH3305CH4406" },
  { productCode: 870015085, configurationCode: "CH1102CH1102CE11CE11CE11" },
  {
    productCode: 870016020,
    configurationCode: "CH0001CH0001CH0001CH0001CH0011CH0011CH0011",
  },
  {
    productCode: 870016006,
    configurationCode: "CH0000CH0001CH0001CH3305CH3305CH1102CE11",
  },
  {
    productCode: 870015087,
    configurationCode: "CH0001CH0001CH0001CH0001CH0001CH0001CH1102CH1104",
  },
  {
    productCode: 870016059,
    configurationCode: "CH0001CH0001CH0001CH0001CH0001CH0001CH1102CH1102",
  },
  {
    productCode: 870016021,
    configurationCode: "CH0001CH0001CH0001CH0001CH0001CH0001CH0001CH3305",
  },
  {
    productCode: 870004022,
    configurationCode: "CH0001CH0001CH0001CH0001CH0001CH0001CH0001CH0001",
  },
  {
    productCode: 870004023,
    configurationCode:
      "CH0002CH0002CH0002CH0002CH0002CH0002CH0002CH0002CH0002CH0002",
  },
  {
    productCode: 870004024,
    configurationCode:
      "CH0001CH0001CH0001CH0001CH0001CH0001CH0001CH0001CH0001CH0001",
  },
  {
    productCode: 870004026,
    configurationCode:
      "CH0000CH0000CH0000CH0000CH0000CH0000CH0000CH0000CH0000CH0000",
  },
  {
    productCode: 870004028,
    configurationCode: "CH0002CH0002CH0002CH0002CH0002CH0002CH0002CH0002",
  },
  {
    productCode: 870004029,
    configurationCode:
      "CH0001CH0001CH0001CH0001CH0001CH0001CH0001CH0001CH0002CH0002",
  },
  {
    productCode: 870004032,
    configurationCode: "CH0002CH0002CH0002CH0002CH0002CH0002CH0002CH0002CH0002",
  },
  {
    productCode: 870004033,
    configurationCode: "CH0001CH0001CH0001CH0001CH0001CH0001CH0001CH0001CH0001",
  },
  {
    productCode: 870004034,
    configurationCode:
      "CH0021CH0021CH0021CH0021CH0021CH0021CH0021CH0021CH0021CH0021",
  },
  {
    productCode: 870004025,
    configurationCode: "CH0002CH0002CH0002CH0002CH0002CH0002CH0002CH0002",
  },
  {
    productCode: 870004036,
    configurationCode:
      "CH0002CH0002CH0002CH0002CH0002CH0002CH0002CH0002CH0002CH0002",
  },
  {
    productCode: 870010008,
    configurationCode:
      "CH1104CH1104CH1104CH1104CH1104CH1104CH1104CH1104CH1104CH1104",
  },
  {
    productCode: 870010009,
    configurationCode:
      "CH1102CH1102CH1102CH1102CH1102CH1102CH1102CH1102CH1102CH1102",
  },
  {
    productCode: 870010010,
    configurationCode:
      "CH1112CH1112CH1112CH1112CH1112CH1112CH1112CH1112CH1112CH1112",
  },
];

export default configurationsTestList;
