import Navbar from "components/navbar/Navbar";
import "./Form.scss";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { useDispatch, useSelector } from "react-redux";
import {
  sendMailToCompany,
  sendMailToCustomer,
  setCircles,
  setLines,
} from "reducer";
import { useState, useEffect } from "react";
import { getCode } from "utils/encodeDecode";
import { Link } from "react-router-dom";

const formInputs = [
  { type: "email", name: "email", required: true, extended: true },
  { type: "text", name: "name", required: true },
  { type: "text", name: "surname", required: true },
  { type: "text", name: "company", required: true },
  { type: "phone", name: "phone" },
  { type: "text", name: "city" },
  { type: "text", name: "country", required: true },
];

const Form = ({ location, history }) => {
  const dispatch = useDispatch();
  const base64 = location?.state?.base64;
  const allResultsBase64 = location?.state?.allResultsBase64;
  const catalogue = location?.state?.catalogue;
  const description = location?.state?.product?.description;
  const name = location?.state?.product?.name;
  const circles = useSelector(({ circles }) => circles);
  const [form, setForm] = useState({
    message: `Please send more info about ${name}`,
  });
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!location.state) {
      // redirect to home
      history.push("/");
    }
  }, []);

  const submitRequest = async () => {
    setSent(false);
    setError(false);
    setLoading(true);

    const result1 = await sendMailToCompany(
      form,
      Object.values(allResultsBase64).flat(2),
      description,
      catalogue,
      base64,
      getCode(circles)
    );
    const result2 = await sendMailToCustomer(form, base64, description);

    if (result1 === 200 && result2 === 200) {
      setSent(true);
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const handleChange = ({ target }) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    setForm({ ...form, [target.name]: value });
  };

  const isDisabled = () => {
    const requiredInputs = formInputs.filter((input) => input.required);
    const inputStatus = requiredInputs.map(
      (input) => !!form[input.name] && form[input.name] !== ""
    );
    const inputAreValid = inputStatus.every((input) => !!input);
    const privacyIsSet = form.privacy;
    return !inputAreValid || !privacyIsSet;
  };

  return (
    <div className="form-view">
      <Navbar right={<Close goBack={history.goBack} />} />

      <div className="form-container container">
        <div style={{ width: "100%", textAlign: "center" }}>
          {/* <p className="title">{`Requesting information for ${description}`}</p> */}
          <p className="title">{`Requesting information for ${name}`}</p>
        </div>
        {!!sent ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              minHeight: "60vh",
              fontSize: 20,
              gap: 24,
            }}
          >
            <div>Congratulation!</div>
            <div>Your request has been successfully sent</div>
            <div>You will be contacted by our team in the next few days</div>
            <Link className="btn" to="/" style={{ marginTop: 16 }}>
              Homepage
            </Link>
          </div>
        ) : (
          <div className="form__wrapper">
            {formInputs.map((input, i) => (
              <input
                key={`${i}_${input.name}`}
                className={!!input.extended && "extended"}
                type={input.type}
                name={input.name}
                placeholder={!!input.required ? `${input.name}*` : input.name}
                onChange={handleChange}
              />
            ))}

            <div className="textarea-box extended">
              <label htmlFor="text-area">message</label>
              <textarea
                id="text-area"
                name="message"
                defaultValue={form.message}
                onChange={handleChange}
              />
            </div>

            <div className="checkbox-box extended">
              <div className="checkbox">
                <input
                  id="privacy"
                  type="checkbox"
                  name="privacy"
                  onChange={handleChange}
                />

                <label htmlFor="privacy">
                  <p>
                    I Agree to{" "}
                    <a
                      href="https://www.stucchigroup.com/privacy-policy/"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "#e6007e", textDecoration: "underline" }}
                    >
                      {" "}
                      Privacy Policy
                    </a>{" "}
                    <sup>(required)</sup>
                  </p>
                </label>
              </div>

              <div className="checkbox">
                <input
                  id="comunications"
                  type="checkbox"
                  name="comunications"
                  onChange={handleChange}
                />

                <label htmlFor="comunications">
                  I Agree to receive Marketing comunications
                </label>
              </div>
            </div>

            <div
              className={
                isDisabled()
                  ? "submit-box extended disabled"
                  : "submit-box extended"
              }
              onClick={submitRequest}
            >
              {loading ? (
                <div className="loader"></div>
              ) : (
                <>
                  <div className="line"></div>
                  <span>Send</span>
                </>
              )}
            </div>
          </div>
        )}

        {!sent && (
          <div style={{ textAlign: "center", marginTop: "2rem" }}>
            {error && (
              <div style={{ color: "red" }}>
                An error occured while sending the email
              </div>
            )}

            <div className="form-footer" style={{ marginTop: "2rem" }}>
              A copy of the request will be sent to your email address.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Form;

const Close = ({ goBack }) => (
  <div className="form__close" onClick={goBack}>
    <span>close</span>
    <Icon path={mdiClose} size={1} />
  </div>
);
