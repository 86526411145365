import axios from "axios";
import { noSpace } from "utils/general";

const SET_LINES = "SET_LINES";
const UPDATE_CIRCLE = "UPDATE_CIRCLE";
const DELETE_CIRCLE = "DELETE_CIRCLE";
const SET_CIRCLES = "SET_CIRCLES";
const SET_RESULTS = "SET_RESULTS";
const SET_OPERATOR_MODE = "SET_OPERATOR_MODE";
const UNSET_OPERATOR_MODE = "UNSET_OPERATOR_MODE";

const initialCircles = [...Array(10)];

const createCircles = (lines, stateCircles) => {
  const circles = [...stateCircles];
  const stateCirclesHydraulic = [...circles].filter(
    (circle) => circle?.type === "hydraulic"
  );
  const stateCirclesElectric = [...circles].filter(
    (circle) => circle?.type === "electric"
  );
  const hydraulicToAdd = Math.max(
    0,
    lines.hydraulic - stateCirclesHydraulic.length
  );

  const hydraulicToRemove = Math.max(
    0,
    stateCirclesHydraulic.length - lines.hydraulic
  );
  const electricToAdd = Math.max(
    0,
    lines.electric - stateCirclesElectric.length
  );
  const electricToRemove = Math.max(
    0,
    stateCirclesElectric.length - lines.electric
  );

  const hydraulicLength = stateCirclesHydraulic.length;
  const electricLength = stateCirclesElectric.length;

  for (let i = 0; i < hydraulicToRemove; i++) {
    stateCirclesHydraulic.pop();
  }

  for (let i = 0; i < electricToRemove; i++) {
    stateCirclesElectric.pop();
  }

  let x;
  for (x = 0; x < hydraulicToAdd; x++) {
    stateCirclesHydraulic.push({
      type: "hydraulic",
      id: `hydraulic_${hydraulicLength + x + 1}`,
      index: hydraulicLength + x + 1,
    });
  }
  for (let y = 0; y < electricToAdd; y++) {
    stateCirclesElectric.push({
      type: "electric",
      id: `electric_${electricLength + x + y + 1}`,
      index: electricLength + x + y + 1,
      setting: {
        poles: { value: 7, label: "Poles 7" },
        body_size: { value: "3/8", label: "Size 3/8" },
      },
    });
  }

  const newCircles = [
    ...stateCirclesHydraulic,
    ...stateCirclesElectric,
    ...initialCircles,
  ].slice(0, 10);

  localStorage.setItem("circles", JSON.stringify(newCircles));

  return newCircles;
};

const resetIndex = (circles) => {
  const count = {
    hydraulic: 0,
    electric: 0,
  };
  return [...circles].map((circle) => {
    if (!circle) {
      return null;
    }
    const newCircle = {
      ...circle,
      id: `${circle.type}_${count[circle.type] + 1}`,
      index: count[circle.type] + 1,
    };
    count[circle.type] += 1;
    return newCircle;
  });
};

const lsCircles = localStorage.getItem("circles")
  ? JSON.parse(localStorage.getItem("circles"))
  : initialCircles;

const initialState = {
  lines: {
    hydraulic: lsCircles.filter((value) => value?.type === "hydraulic").length,
    electric: lsCircles.filter((value) => value?.type === "electric").length,
  },
  is_hydraulic_set: localStorage.getItem("hydraulic"),
  is_electric_set: localStorage.getItem("electric"),
  circles: lsCircles,
  results: null,
  operator_mode: false,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LINES: {
      const newLines = {
        ...state.lines,
        [action.payload.target]: action.payload.value,
      };

      if (action.payload.value === null) {
        localStorage.removeItem(action.payload.target);
      } else {
        localStorage.setItem(action.payload.target, JSON.stringify(true));
      }

      return {
        ...state,
        lines: {
          ...newLines,
        },
        circles: createCircles(newLines, state.circles),
        [`is_${action.payload.target}_set`]:
          action.payload.value === null ? null : true,
      };
    }

    case UPDATE_CIRCLE: {
      const newCircles = state.circles.map((circle) => {
        if (circle?.id === action.payload.id) {
          if (action.payload.updates) {
            return {
              ...circle,
              setting: { ...circle.setting, ...action.payload.updates },
            };
          } else {
            const copy = circle;
            delete copy.setting;
            return copy;
          }
        }
        return circle;
      });

      localStorage.setItem("circles", JSON.stringify(newCircles));

      return {
        ...state,
        circles: newCircles,
      };
    }

    case DELETE_CIRCLE: {
      let newCircles = [
        ...state.circles.filter((circle) => circle?.id !== action.payload.id),
      ];

      newCircles.push(null);

      newCircles = resetIndex(newCircles);

      localStorage.setItem("circles", JSON.stringify(newCircles));

      return {
        ...state,
        circles: newCircles,
        lines: {
          hydraulic:
            state.lines.hydraulic -
            (action.payload.type === "hydraulic" ? 1 : 0),
          electric:
            state.lines.electric - (action.payload.type === "electric" ? 1 : 0),
        },
      };
    }

    case SET_CIRCLES: {
      localStorage.setItem("circles", JSON.stringify(action.payload));

      return {
        ...state,
        circles: action.payload,
        lines: {
          hydraulic: action.payload.filter(
            (value) => value?.type === "hydraulic"
          ).length,
          electric: action.payload.filter((value) => value?.type === "electric")
            .length,
        },
      };
    }

    case SET_RESULTS: {
      localStorage.setItem("results", JSON.stringify(action.payload));

      return {
        ...state,
        results: action.payload,
      };
    }
    case SET_OPERATOR_MODE: {
      localStorage.setItem("operator_mode", true);

      return {
        ...state,
        operator_mode: true,
      };
    }
    case UNSET_OPERATOR_MODE: {
      localStorage.removeItem("operator_mode");

      return {
        ...state,
        operator_mode: false,
      };
    }

    default:
      return state;
  }
};

export default rootReducer;

export const setLines = (target, value) => async (dispatch) =>
  dispatch({
    type: SET_LINES,
    payload: { target, value },
  });

export const updateCircle = (id, updates) => async (dispatch) =>
  dispatch({
    type: UPDATE_CIRCLE,
    payload: { id, updates },
  });

export const deleteCircle = (id, type) => async (dispatch) =>
  dispatch({
    type: DELETE_CIRCLE,
    payload: { id, type },
  });

export const setCircles = (circles) => async (dispatch) =>
  dispatch({
    type: SET_CIRCLES,
    payload: circles,
  });

export const setReduxResults = (results) => async (dispatch) => {
  dispatch({
    type: SET_RESULTS,
    payload: results,
  });
};
export const setOperatorMode = () => (dispatch) => {
  dispatch({
    type: SET_OPERATOR_MODE,
  });
};
export const unsetOperatorMode = () => (dispatch) => {
  dispatch({
    type: UNSET_OPERATOR_MODE,
  });
  window.location.reload();
};

export const sendMailToCompany = async (
  form,
  boh,
  description,
  boh2,
  base64,
  code
) => {
  try {
    const response = await axios({
      url: "https://api.multiconfigurator.stucchigroup.com/send-email-company",
      // url: "https://api.stucchiconfiguratore.thecove.it/send-email-company",
      /* url: "http://localhost:6284/send-email-company", */
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        form,
        description,
        base64,
        code,
      },
    });
    if (response) {
      window.dataLayer.push({
        event: "info_requested",
      });
    }
    return response.status;
  } catch (error) {
    console.log("ERROR", error);
    return null;
  }
};

export const sendMailToCustomer = async (form, base64, description) => {
  try {
    const response = await axios({
      url: "https://api.multiconfigurator.stucchigroup.com/send-email-user",
      // url: "https://api.stucchiconfiguratore.thecove.it/send-email-user",
      // url: "http://localhost:6284/send-email-user",
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        form,
        description,
        base64,
      },
    });
    return response.status;
  } catch (error) {
    console.log("ERROR", error);
    return null;
  }
};
