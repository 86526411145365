import Navbar from "components/navbar/Navbar";
import { useEffect, useState } from "react";
import Icon from "@mdi/react";
import { mdiCheck, mdiMinus, mdiPlus, mdiTrashCan } from "@mdi/js";
import { useDispatch, useSelector } from "react-redux";
import SelectAreaBox from "./select-area-box/SelectAreaBox";
import circlesOptions from "db/line_configuration_options.json";
import "./Configurator.scss";
import StepsConfig from "./steps-config/StepsConfig";
import { deleteCircle } from "reducer";
import ConfigurationPanel from "./configuraton-panel/ConfigurationPanel";
import { Popup } from "components/popups/Popup";

const getIsCompleted = (circle) => {
  if (!circle) {
    return false;
  }

  return circlesOptions[circle.type].every(
    (option) => !!circle.setting && !!circle.setting[option.id]
  );
};

const Configurator = () => {
  const dispatch = useDispatch();
  const [activeCircle, setActiveCircle] = useState([]);
  const [indexOfFirstIncomplete, setIndexOfFirstIncomplete] = useState(null);
  const circles = useSelector(({ circles }) => circles);
  const isElectricSet = useSelector(({ is_electric_set }) => is_electric_set);
  const hydraulicLines = useSelector(({ lines }) => lines.hydraulic);
  const electricLines = useSelector(({ lines }) => lines.electric);
  const almostLines = hydraulicLines > 0 || electricLines > 0;
  const isHydraulicSet = useSelector(
    ({ is_hydraulic_set }) => is_hydraulic_set
  );
  const hydraulicCircles = circles.filter(
    (circle) => circle?.type === "hydraulic" && circle.setting
  ).length;
  const checkStepElectric = isElectricSet && almostLines;
  const checkStepHydraulicConfigured =
    almostLines && isHydraulicSet && hydraulicCircles === hydraulicLines;

  const checkHandleCircle = (element) => {
    if (element?.type === "hydraulic" && checkStepElectric) {
      handleCircle(element);
    } else {
      if (checkStepHydraulicConfigured) {
        handleCircle(element);
      }
    }
  };

  const handleCircle = (element) =>
    activeCircle.find((circle) => circle === element)
      ? setActiveCircle([
          ...activeCircle.filter((circle) => circle.id !== element.id),
        ])
      : setActiveCircle([...activeCircle, element]);

  const handleDelete = (element) => {
    setActiveCircle([
      ...activeCircle.filter((circle) => circle.id !== element.id),
    ]);
    dispatch(
      deleteCircle(
        activeCircle.find((circle) => circle.id === element.id).id,
        activeCircle.find((circle) => circle.id === element.id).type
      )
    );
  };

  useEffect(() => {
    const reverseCircles = [...circles].reverse();
    reverseCircles.map((circle, index) => {
      if (!getIsCompleted(circle) && circle?.type === "hydraulic") {
        setIndexOfFirstIncomplete(reverseCircles.length - 1 - index);
      }
    });
  }, [circles]);

  return (
    <>
      {/* <Navbar title="Configurator" right={<StepsConfig />} /> */}
      <Navbar title="Configurator" />

      <div className="configurator-view container">
        <div className="wrapper-preview">
          <div className="configurator__side-panel ">
            <SelectAreaBox setActiveCircle={setActiveCircle} />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <StepsConfig />
            <div className="configurator__preview" style={{ marginTop: 24 }}>
              {circles.map((element, i) => {
                const isActive = activeCircle.some(
                  (circle) => element?.id === circle?.id
                );
                const isCompleted = getIsCompleted(element);
                const number = i + 1;
                return (
                  <div
                    title={
                      element?.type
                        ? isCompleted
                          ? ""
                          : isActive
                          ? "Close"
                          : "Configure"
                        : ""
                    }
                    key={`circle_${i}`}
                    number={number}
                    onClick={() => checkHandleCircle(element)}
                    element={element}
                    className={`preview__circle 
                    ${element?.type || ""} 
                    ${isActive ? "active" : ""} 
                    ${
                      isCompleted || element?.type === "electric"
                        ? "complete"
                        : ""
                    } ,
                  `}
                  >
                    <div className="number">{number}</div>

                    {activeCircle.length === 1 && isActive && (
                      <Icon
                        className="delete"
                        onClick={() => handleDelete(element)}
                        path={mdiTrashCan}
                        title="Delete Configuration"
                        size={1}
                      />
                    )}

                    <Icon
                      className="check"
                      path={
                        isCompleted || element?.type === "electric"
                          ? mdiCheck
                          : isActive
                          ? mdiMinus
                          : mdiPlus
                      }
                      size={1}
                    />
                    {isHydraulicSet &&
                      isElectricSet &&
                      !checkStepHydraulicConfigured &&
                      !isActive &&
                      i === indexOfFirstIncomplete && (
                        <div className="btn-tooltip">Click on the circle</div>
                      )}
                  </div>
                );
              })}
            </div>
            <div
              style={{ fontSize: "0.9rem", paddingTop: 4, fontStyle: "italic" }}
            >
              <i>
                The hydraulic lines presented in this configurator are intended
                for use with hydraulic oil only; for use with other fluids,
                please contact Stucchi support.
              </i>
            </div>
          </div>
          <div className="configurator__side-panel lines-panel">
            {activeCircle.length > 0 && (
              <ConfigurationPanel
                setActiveCircle={setActiveCircle}
                activeCircle={activeCircle}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Configurator;
