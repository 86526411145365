import { mdiChevronDown, mdiChevronUp, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { useSelector } from "react-redux";
import useComponentVisible from "utils/useComponentVisible";
import "./SelectArea.scss";

const SelectArea = (props) => {
  const { ref, isComponentVisible, setIsComponentVisible, toggling } =
    useComponentVisible(false);
  const isHydraulicSet = useSelector(
    ({ is_hydraulic_set }) => is_hydraulic_set
  );
  const isElectricSet = useSelector(({ is_electric_set }) => is_electric_set);
  const hydraulicLines = useSelector(({ lines }) => lines.hydraulic);
  const checkStepHydraulic =
    props.target === "hydraulic" || isHydraulicSet || hydraulicLines > 0;

  const handleChange = (option) => {
    props.onChange(props.target, option);
    setIsComponentVisible(false);
  };

  const handleReset = (event) => {
    event.stopPropagation();
    props.onChange(props.target, null);
  };

  return (
    <div
      ref={ref}
      className={`select-area-box__select ${props.target} ${
        isComponentVisible ? "active" : ""
      }`}
    >
      <div
        onClick={checkStepHydraulic ? toggling : null}
        className="placeholder"
      >
        <span className="text">
          {props.target} lines
          {props.value !== null ? ` - ${props.value}` : ""}
        </span>

        {props.value !== null ? (
          <Icon path={mdiClose} size={1} onClick={handleReset} />
        ) : (
          <Icon
            path={isComponentVisible ? mdiChevronUp : mdiChevronDown}
            size={1}
          />
        )}
        {props.target === "hydraulic" && !isHydraulicSet && (
          <div className="btn-tooltip">Select Hydraulic Lines</div>
        )}
        {props.target === "electric" && isHydraulicSet && !isElectricSet && (
          <div className="btn-tooltip-reverse">Select Electric Lines</div>
        )}
      </div>

      {isComponentVisible && (
        <div className="options-container">
          {props.options
            .filter((value) => value <= props.max)
            .map((option, i) => (
              <div
                key={`option_${i}`}
                onClick={() => handleChange(option)}
                className="option"
              >
                {option}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default SelectArea;

// for loop from the end of the array
// for (let i = props.options.length - 1; i >= 0; i--) {
//   if (props.options[i] <= props.max) {
