import Icon from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import "./BottomBar.scss";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const BottomBar = () => {
  const { pathname } = useLocation();
  const isHydraulicSet = useSelector(
    ({ is_hydraulic_set }) => is_hydraulic_set
  );
  // const isElectricSet = useSelector(({ is_electric_set }) => is_electric_set);
  const hydraulicLines = useSelector(({ lines }) => lines.hydraulic);
  const electricLines = useSelector(({ lines }) => lines.electric);
  const almostLines = hydraulicLines > 0 || electricLines > 0;
  const circles = useSelector(({ circles }) => circles);
  const hydraulicCircles = circles.filter(
    (circle) => circle?.type === "hydraulic" && circle.setting
  ).length;
  // const electricCircles = circles.filter(
  //   (circle) => circle?.type === "electric" && circle.setting
  // ).length;
  const isCompleted =
    almostLines &&
    isHydraulicSet &&
    // isElectricSet &&
    hydraulicCircles === hydraulicLines;
  // &&
  // electricCircles === electricLines;

  return (
    <div className="bottom-bar-component">
      {pathname !== "/results" && (
        <div
          className={`move-section-left ${
            pathname === "/catalogue" || pathname === "/form" ? "active" : ""
          }`}
        >
          <span className="forward">configurator</span>

          <Link
            to={pathname === "/" ? "/catalogue" : "/"}
            className="arrow-box"
          >
            <Icon path={mdiChevronLeft} size={1} />
          </Link>
          <span className="back">all models available</span>
        </div>
      )}

      {isCompleted && pathname !== "/catalogue" && (
        <div
          className={`move-section-right ${
            pathname === "/results" || pathname === "/form" ? "active" : ""
          }`}
        >
          <div className="tooltip-results">see results</div>

          <span className="forward">go</span>

          <Link to={pathname === "/" ? "/results" : "/"} className="arrow-box">
            <Icon path={mdiChevronRight} size={1} />
          </Link>

          <span className="back">new setup</span>
        </div>
      )}
    </div>
  );
};

export default BottomBar;
