import linesConfigurationOptions from "db/line_configuration_options.json";
import { Fragment } from "react";
import { formatLabelSpecs, formatResultsSpecs } from "utils/general";
import "./Specs.scss";

// const Specs = ({ circles, type }) => (
//   <div className="results-view__specs">
//     <table className="specs-table">
//       <thead className={`${type === "electric" ? "secondary" : ""}`}>
//         <tr>
//           <th className="value">
//             <div>{type} lines</div>
//           </th>

//           {linesConfigurationOptions[type].map((element, i) => (
//             <th className="value" key={i}>
//               {formatLabelSpecs(element.id)}
//             </th>
//           ))}
//         </tr>
//       </thead>
//       <tbody>
//         {circles
//           .filter((value) => value?.type === type)
//           .map((element, i) => (
//             <Fragment key={i}>
//               <tr className={`${type === "electric" ? "secondary" : ""}`}>
//                 <th>Line {element.index}</th>

//                 {linesConfigurationOptions[type].map((key, i) => {
//                   return (
//                     <td className="value" key={i}>
//                       {/* {element.setting[key.id]?.value} */}
//                       {formatResultsSpecs(element, key, type === "electric")}
//                     </td>
//                   );
//                 })}
//               </tr>
//             </Fragment>
//           ))}
//       </tbody>
//     </table>
//   </div>
// );

const Specs = ({ circles, type }) => (
  <div className="results-view__specs">
    <table className="specs-table">
      <thead className={`${type === "electric" ? "secondary" : ""}`}>
        <tr>
          <th className="value">
            <div>{type} lines</div>
          </th>

          {type !== "electric" &&
            linesConfigurationOptions[type].map((element, i) => (
              <th className="value" key={i}>
                {formatLabelSpecs(element.id)}
              </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {type !== "electric" ? (
          circles
            .filter((value) => value?.type === type)
            .map((element, i) => (
              <Fragment key={i}>
                <tr>
                  <th>Line {element.index}</th>

                  {linesConfigurationOptions[type].map((key, i) => {
                    return (
                      <td className="value" key={i}>
                        {/* {element.setting[key.id]?.value} */}
                        {formatResultsSpecs(element, key, type === "electric")}
                      </td>
                    );
                  })}
                </tr>
              </Fragment>
            ))
        ) : (
          <tr className="secondary">
            <th>{`${
              circles.filter((value) => value?.type === type).length
            } Line${
              circles.filter((value) => value?.type === type).length > 1
                ? "s"
                : ""
            } `}</th>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);

export default Specs;
