import logo from "assets/logo.svg";
import "./Navbar.scss";

const Navbar = ({ title, right }) => (
  <div className="navbar">
    <a href="https://www.stucchigroup.com/" target="_blank" rel="noreferrer">
      <img src={logo} alt="" />
    </a>

    <div className="navbar__content">
      <div className="title">{title}</div>

      {right}
    </div>
  </div>
);

export default Navbar;
