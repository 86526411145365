import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import formattedPdf from "../components/detail-product/FormatPdf.js";
pdfMake.addVirtualFileSystem(pdfFonts);

export const printPdf = async (
  catalogue,
  circles,
  prod,
  type,
  filteredCouplings,
  printPage,
  image,
  formattedPlacca
) => {
  console.log("image", image);
  const thisPdf = formattedPdf(
    catalogue,
    circles,
    prod,
    type,
    filteredCouplings,
    printPage,
    image,
    formattedPlacca
  );
  pdfMake.createPdf(thisPdf).print();
  window.dataLayer.push({
    event: "pdf_printed",
  });
};
