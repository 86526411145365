import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import formattedPdf from "../components/detail-product/FormatPdf.js";
pdfMake.addVirtualFileSystem(pdfFonts);

export const getBase64 = async (
  catalogue,
  circles,
  prod,
  type,
  filteredCouplings,
  printPage,
  imgBase64,
  formattedPlacca
) => {
  const thisPdf = formattedPdf(
    catalogue,
    circles,
    prod,
    type,
    filteredCouplings,
    printPage,
    imgBase64,
    formattedPlacca
  );
  const pdf = pdfMake
    .createPdf(thisPdf)
    .getBase64()
    .then(
      (data) => {
        return data;
      },
      (err) => {
        console.error(err);
      }
    );

  return pdf;
};
