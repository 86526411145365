import "./ResultsList.scss";
import DetailProduct from "components/detail-product/DetailProduct";
import { Link } from "react-router-dom";
import useComponentVisible from "utils/useComponentVisible";
import Icon from "@mdi/react";

const ResultsList = ({ results, title, couple }) => {
  const { ref, isComponentVisible, open, close } = useComponentVisible(false);

  return (
    <div className="results-view__results-list">
      {isComponentVisible && (
        <DetailProduct
          propRef={ref}
          product={isComponentVisible}
          close={close}
        />
      )}

      {!couple ? (
        <div className="results-list">
          {results?.length > 0 ? (
            <table className="result-product-table">
              <thead>
                <tr>
                  <th>code</th>
                  <th>name</th>
                  <th>description</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {results?.map((element, i) => (
                  <tr>
                    <td style={{ textAlign: "center" }}>{element.code}</td>
                    <td>{element.name}</td>
                    <td>{element.description}</td>
                    <td style={{ textAlign: "center" }}>
                      <div className="btn table" onClick={() => open(element)}>
                        details
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>No products found.</div>
          )}
          {/* {(results || [])?.map((element, i) => (
            <div key={i} className="result">
              <div className="result__left">
                <span>{element.code}</span>
                <span>{element.description}</span>
              </div>

              <div className="cta">
                <div className="btn" onClick={() => open(element)}>
                  details
                </div>
                <Link
                  to={{ pathname: "/form", state: element }}
                  className="btn outline"
                >
                  request
                </Link>
              </div>
            </div>
          ))} */}
        </div>
      ) : results?.length > 0 ? (
        <div className="results-list-couple">
          {results?.map((element, i) => (
            <div className="results-couple" key={i}>
              <table className="result-product-table">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center", flex: 4 }}>code</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "center", flex: 4 }}>
                      {element.code} COMPONENTS
                    </td>
                    {/* <td>{element.description}</td> */}
                    <td style={{ textAlign: "center" }}>
                      <div
                        className="btn table"
                        onClick={() => open(element.couple[0])}
                      >
                        details
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
      ) : (
        <div>No products found.</div>
      )}

      {/* {(results || []).length === 0 && <div>No products found.</div>} */}
    </div>
  );
};

export default ResultsList;

//  {
//    element.couple.length !== 1 && (
//      <>
//        <Icon path={mdiLink} size={1.5} />

//        <table className="result-product-table" style={{ width: "46%" }}>
//          <thead>
//            <tr>
//              <th>code</th>
//              <th>name</th>
//              <th></th>
//            </tr>
//          </thead>
//          <tbody>
//            <tr>
//              <td style={{ textAlign: "center" }}>{element.couple[1].code}</td>
//              <td>{element.couple[1].name}</td>
//              {/* <td>{element.description}</td> */}
//              <td style={{ textAlign: "center" }}>
//                <div
//                  className="btn table"
//                  onClick={() => open(element.couple[1])}
//                >
//                  details
//                </div>
//              </td>
//            </tr>
//          </tbody>
//        </table>
//      </>
//    );
//  }
