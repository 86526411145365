import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

function format() {
  let address = window.location.pathname.split("/");
  if (address[1] !== "") {
    return [address[1]];
  } else {
    return "";
  }
}

function MyHelmet() {
  const [path, setPath] = useState(format());
  const { pathname } = useLocation();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      originalLocation:
        document?.location?.protocol +
        "//" +
        document?.location?.hostname +
        document?.location?.pathname +
        document?.location?.search,
    });
    window.dataLayer.push({
      event: "Pageview",
      page_title: path || "",
      page_URL: window?.location?.href || "",
      page_path: window?.location?.pathname || "",
      page_hash: window?.location?.hash || "",
      page_query: window?.location?.search || "",
      page_location:
        (window?.location?.href || "") + (window?.location?.search || ""),
    });
    setPath(format());
  }, [pathname]);

  return (
    <Helmet>
      <title>{`Configuratore - Stucchi ${path && `- ${path}`}`}</title>
    </Helmet>
  );
}

export default MyHelmet;
