import lineConfigs from "db/line_configuration_options.json";

const initalCircle = [
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
];

const settings = {
  hydraulic: ["body_size", "litre", "thread", "port"],
  electric: ["body_size", "poles"],
};

const getCircleCode = (circle) => {
  const separator = "C";

  const type = circle.type.substring(0, 1).toUpperCase();

  let configs;
  if (typeof circle.setting !== "undefined") {
    configs = settings[circle.type]
      .map((key) => {
        const typeConfigs = lineConfigs[circle.type];
        const currentConfig = typeConfigs.find((tC) => tC.id === key);
        const option = currentConfig.options.findIndex(
          (cC) => cC.value === circle.setting[key].value
        );
        return option;
      })
      .join("");
  } else {
    configs = "N";
  }
  return `${separator}${type}${configs}`;
};

export const getCode = (circles) => {
  return circles
    .filter((c) => !!c)
    .map(getCircleCode)
    .join("");
};

export const decode = (code) => {
  let circlesCodes = code.split("C").slice(1);
  circlesCodes = circlesCodes.map((cC, i) => {
    const type = cC[0] === "H" ? "hydraulic" : "electric";

    const isNull = cC[1] === "N";

    if (!isNull) {
      let circleSettings = cC.slice(1).split("");
      const typeSettings = settings[type];
      circleSettings = circleSettings.reduce((acc, curr, i) => {
        const key = typeSettings[i];
        const value = lineConfigs[type].find((lC) => lC.id === key).options[
          curr
        ];
        const copy = { ...value };
        delete copy.reference;
        return {
          ...acc,
          [key]: copy,
        };
      }, {});

      return {
        type,
        index: i + 1,
        setting: circleSettings,
      };
    } else {
      return {
        type,
        index: i + 1,
      };
    }
  });

  let hydraulicCircles = circlesCodes.filter(
    (circle) => circle.type === "hydraulic"
  );
  hydraulicCircles = hydraulicCircles.map((c, i) => {
    return {
      id: `${c.type}_${i + 1}`,
      ...c,
    };
  });

  let electricCircles = circlesCodes.filter(
    (circle) => circle.type === "electric"
  );
  electricCircles = electricCircles.map((c, i) => {
    return {
      id: `${c.type}_${i + 1}`,
      ...c,
    };
  });
  return [...hydraulicCircles, ...electricCircles, ...initalCircle].slice(
    0,
    10
  );
};

export const isValidCode = (code) => {
  if (!code) {
    return false;
  }
  let circlesCode = code.split("C");
  if (circlesCode.length === 1 || circlesCode[0] !== "") {
    return false;
  }
  circlesCode.shift();
  return circlesCode.every((element) => {
    if (!element) {
      return false;
    }
    const type = element[0] === "H" ? "hydraulic" : "electric";
    const configs = element.slice(1).split("");
    const configsIsCorrect =
      configs[0] === "N" ||
      configs.every((c, i) => {
        return (
          !isNaN(c) &&
          lineConfigs[type][i].options.length >= Number(c) &&
          Number(c) >= 0
        );
      });
    return (
      (type === "hydraulic" || type === "electric") &&
      configsIsCorrect &&
      (configs[0] === "N" ||
        (type === "hydraulic" ? configs.length === 4 : configs.length === 2))
    );
  });
};
