import products from "db/products.json";
import productsConfiguration from "db/products_configurations.json";
import { replaceChar } from "utils/general";

const blueTableLayout = {
  // hLineWidth: function (i, node) {
  //   return i === 0 || i === node.table.body.length ? 2 : 1;
  // },
  // vLineWidth: function (i, node) {
  //   return i === 0 || i === node.table.widths.length ? 2 : 1;
  // },
  hLineColor: function (i, node) {
    return i === 0 ? "#005984" : "#ccc";
  },
  vLineColor: "#ccc",
  fillColor: function (rowIndex, node, columnIndex) {
    return rowIndex === 0 ? "#005984" : rowIndex % 2 === 0 ? "#deeaf6" : null;
  },
};

const whiteTableLayout = {
  // hLineWidth: function (i, node) {
  //   return i === 0 || i === node.table.body.length ? 2 : 1;
  // },
  // vLineWidth: function (i, node) {
  //   return i === 0 || i === node.table.widths.length ? 2 : 1;
  // },
  hLineColor: function (i, node) {
    return i === 0 ? "#005984" : "#ccc";
  },
  vLineColor: "#ccc",
  fillColor: function (rowIndex, node, columnIndex) {
    return rowIndex === 0 ? "#005984" : null;
  },
};

const pinkTableLayout = {
  // hLineWidth: function (i, node) {
  //   return i === 0 || i === node.table.body.length ? 2 : 1;
  // },
  // vLineWidth: function (i, node) {
  //   return i === 0 || i === node.table.widths.length ? 2 : 1;
  // },
  hLineColor: function (i, node) {
    return i === 0 ? "#e6007e" : "#ccc";
  },
  vLineColor: "#ccc",

  fillColor: function (rowIndex, node, columnIndex) {
    return rowIndex === 0 ? "#e6007e" : rowIndex % 2 === 0 ? "#fadaec" : null;
  },
};

const splitAndShift = (string) => {
  return string.split(" ").slice(1).join(" ");
};

const formattedPdf = (
  catalogue,
  circles,
  product,
  type,
  filteredCouplings,
  printPage,
  image,
  formattedPlacca
) => {
  const getConfigurationQuantity = (name) => {
    let quantity = 0;
    formattedPlacca.map((circle) => {
      const number = Object.keys(circle).filter((code) => code === name).length;
      quantity += number;
    });
    return quantity;
  };

  // TABELLE CONFIGURAZIONE

  const getConfigurationData = (circles, type) => {
    return circles
      .filter((circle) => !!circle && circle.type === type)
      .map((circle, i) => {
        if (type === "hydraulic") {
          return [
            { style: "cellStyle", text: `LINE ${i + 1}` },
            { style: "cellStyle", text: circle.setting?.body_size.label },
            {
              style: "cellStyle",
              text: `${circle.setting?.litre.value} l/min`,
            },
            { style: "cellStyle", text: circle.setting?.thread.value },
            { style: "cellStyle", text: `${circle.setting?.port.value}"` },
          ];
        } else if (type === "electric") {
          return [
            {
              style: "cellStyle",
              text: `${
                circles.filter((value) => value?.type === type).length
              } Line${
                circles.filter((value) => value?.type === type).length > 1
                  ? "s"
                  : ""
              } `,
            },
            // { style: "cellStyle", text: `${circle.setting?.body_size.value}"` },
            // { style: "cellStyle", text: circle.setting?.poles.value },
          ];
        }
      });
  };

  const getConfigurationSection = (catalogue, circles) => {
    if (!catalogue) {
      if (circles.some((circle) => !!circle && circle.type === "electric")) {
        return [
          { text: "YOUR CONFIGURATION", style: "header" },
          {
            style: "tableExample",
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "*", "*"],
              body: [
                [
                  { text: "Hydraulic Lines", style: "tableHeader" },
                  { text: "Body Size", style: "tableHeader" },
                  { text: "Flow Rate", style: "tableHeader" },
                  { text: "Thread", style: "tableHeader" },
                  { text: "Port", style: "tableHeader" },
                ],
                ...getConfigurationData(circles, "hydraulic"),
              ],
            },
            layout: blueTableLayout,
          },
          {
            style: "tableExample",
            table: {
              headerRows: 1,
              // widths: ["*", "*", "*"],
              widths: ["*"],
              body: [
                [
                  { text: "Electric Lines", style: "tableHeader" },
                  // { text: "Body Size", style: "tableHeader" },
                  // { text: "Poles", style: "tableHeader" },
                ],
                ...getConfigurationData(circles, "electric"),
              ],
            },
            layout: pinkTableLayout,
          },
        ];
      } else {
        return [
          { text: "YOUR CONFIGURATION", style: "header" },
          {
            style: "tableExample",
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "*", "*"],
              body: [
                [
                  {
                    text: "Hydraulic Lines",
                    style: "tableHeader",
                  },
                  { text: "Body Size", style: "tableHeader" },
                  { text: "Flow Rate", style: "tableHeader" },
                  { text: "Thread", style: "tableHeader" },
                  { text: "Port", style: "tableHeader" },
                ],
                ...getConfigurationData(circles, "hydraulic"),
              ],
            },
            layout: blueTableLayout,
          },
        ];
      }
    } else return [];
  };

  ////////IMMAGINE PRODOTTO

  const getImage = (printPage, image) => {
    // console.log("image", image);
    if (printPage) {
      return [
        {
          image,
          width: 300,
          margin: [100, 10, 0, 10],
        },
      ];
    } else {
      return [];
    }
  };

  // TABELLE PRODOTTI

  const getProductsSection = (product, type, filteredCouplings, catalogue) => {
    if (
      (type === "COMPLETE" || type === "FIX" || type === "MOBILE") &&
      !product.name.includes("EMPTY")
    ) {
      return [...getNotEmptyProductSection(product, type, filteredCouplings)];
    } else {
      return [
        ...getEmptyProductSection(product, type, filteredCouplings, catalogue),
      ];
    }
  };

  /////////////////////// TABELLE PRODOTTI NON EMPTY ///////////////////////////

  const getIncludedProducts = (product, type, couplings, cap) => {
    const related = products
      .filter((prod) => {
        if (type !== "COMPLETE") {
          return (
            prod.picture === product.picture &&
            prod.name.includes("EMPTY") &&
            prod.name.includes(type === "FIX" ? "FIX" : "MOBILE")
          );
        } else {
          if (!product.name.includes("V0")) {
            return (
              prod.picture === product.picture &&
              prod.name.includes("EMPTY") &&
              !prod.name.includes("V0")
            );
          } else {
            return (
              prod.picture === product.picture && prod.name.includes("EMPTY")
            );
          }
        }
      })
      .map((component, i) => {
        return [
          { style: "cellStyle", text: component.code },
          { style: "cellStyle", text: "1" },
          { style: "cellStyle", text: component.name },
        ];
      });

    let couplingMRow = [];
    const couplingM = couplings.map((coup, i) => {
      const matched = productsConfiguration.find(
        ({ code }) => code === coup[0]
      );

      if (type === "COMPLETE" || type === "FIX") {
        return [
          { style: "cellStyle", text: matched?.mobile },
          { style: "cellStyle", text: coup[1] },
          { style: "cellStyle", text: `M ${coup[0]}` },
        ];
      } else return null;
    });
    if (!!couplingM && !!couplingM[0]) {
      couplingMRow = couplingM;
    }

    let couplingFRow = [];
    const couplingF = couplings.map((coup, i) => {
      const matched = productsConfiguration.find(
        ({ code }) => code === coup[0]
      );
      if (type === "COMPLETE" || type === "MOBILE") {
        return [
          { style: "cellStyle", text: matched?.fixed },
          { style: "cellStyle", text: coup[1] },
          { style: "cellStyle", text: `F ${coup[0]}` },
        ];
      } else return null;
    });
    if (!!couplingF && !!couplingF[0]) {
      couplingFRow = couplingF;
    }

    let capRow = [];
    if (product.cap) {
      // capRow = [product.cap.split(" ")[0], "1", spareParts.join(" ")];
      capRow = [
        [
          { style: "cellStyle", text: product.cap.split(" ")[0] },
          { style: "cellStyle", text: "1" },
          {
            style: "cellStyle",
            text: product.cap.substring(product.cap.indexOf(" ") + 1),
          },
        ],
      ];
    }

    return [...related, ...couplingFRow, ...couplingMRow, ...capRow];
  };

  const getRelatedProducts = (product, type, couplings, cap) => {
    if (type === "COMPLETE") {
      return products
        .filter((prod) => {
          const mobileCode = replaceChar(product.code, "1", -3);
          const fixCode = replaceChar(product.code, "2", -3);

          return prod.code === mobileCode || prod.code === fixCode;
        })
        .map((component) => {
          return [
            { style: "cellStyle", text: component.code },
            { style: "cellStyle", text: "1" },
            { style: "cellStyle", text: component.name },
          ];
        });
    } else if (type === "MOBILE" || type === "FIX") {
      const relatedRows = products
        .filter(
          (prod) =>
            prod.picture === product.picture &&
            prod.name.includes("EMPTY") &&
            prod.name.includes(type !== "FIX" ? "FIX" : "MOBILE")
        )
        .map((component, i) => {
          return [
            { style: "cellStyle", text: component.code },
            { style: "cellStyle", text: "1" },
            { style: "cellStyle", text: component.name },
          ];
        });

      const couplingRows = couplings.map((coup, i) => {
        const matched = productsConfiguration.find(
          ({ code }) => code === coup[0]
        );
        if (type !== "MOBILE") {
          return [
            { style: "cellStyle", text: matched?.fixed },
            { style: "cellStyle", text: coup[1] },
            { style: "cellStyle", text: `F ${coup[0]}` },
          ];
        } else if (type !== "FIX") {
          return [
            { style: "cellStyle", text: matched?.mobile },
            { style: "cellStyle", text: coup[1] },
            { style: "cellStyle", text: `M ${coup[0]}` },
          ];
        }
      });
      let capRow = [];
      if (product.cap && type !== "FIX") {
        // capRow = [product.cap.split(" ")[0], "1", spareParts.join(" ")];
        capRow = [
          [
            { style: "cellStyle", text: product.cap.split(" ")[0] },
            { style: "cellStyle", text: "1" },
            {
              style: "cellStyle",
              text: product.cap.substring(product.cap.indexOf(" ") + 1),
            },
          ],
        ];
      }
      let parkingStation = [];
      if (product.parking_station && type !== "MOBILE") {
        // capRow = [product.cap.split(" ")[0], "1", spareParts.join(" ")];
        parkingStation = [
          [
            { style: "cellStyle", text: product.parking_station.split(" ")[0] },
            { style: "cellStyle", text: "1" },
            {
              style: "cellStyle",
              text: product.parking_station.substring(
                product.parking_station.indexOf(" ") + 1
              ),
            },
          ],
        ];
      }
      return [...relatedRows, ...couplingRows, ...capRow, ...parkingStation];
    }
  };

  const getNotEmptyProductSection = (product, type, filteredCouplings) => {
    let tables = [
      // {
      //   image: imgBase64,
      //   width: 200,
      // },
      { text: "YOUR PRODUCT", style: "header" },
      {
        table: {
          headerRows: 1,
          widths: ["auto", "auto", "*"],
          body: [
            [
              { text: "Code", style: "tableHeader" },
              { text: "Name", style: "tableHeader" },
              { text: "Description", style: "tableHeader" },
            ],
            [
              {
                style: "cellStyle",
                text: product.code,
              },
              { style: "cellStyle", text: product.name },
              {
                style: "cellStyle",
                text: product.description,
              },
            ],
          ],
        },
        layout: whiteTableLayout,
      },
      {
        text: `Max operating resistance is ${product.max_resistance}`,
        style: "tableFooter",
      },
      { text: "COMPONENTS INCLUDED", style: "subHeader" },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          widths: ["auto", "auto", "*"],
          body: [
            [
              { text: "Code", style: "tableHeader" },
              { text: "Quantity", style: "tableHeader" },
              { text: "Description", style: "tableHeader" },
            ],
            ...getIncludedProducts(product, type, filteredCouplings),
          ],
        },
        layout: blueTableLayout,
      },
      { text: "RELATED PRODUCTS ", style: "subHeader" },
      {
        style: "tableExample",
        table: {
          widths: ["auto", "auto", "*"],
          headerRows: 1,
          body: [
            [
              { text: "Code", style: "tableHeader" },
              { text: "Quantity", style: "tableHeader" },
              { text: "Description", style: "tableHeader" },
            ],
            ...getRelatedProducts(product, type, filteredCouplings),
          ],
        },
        layout: blueTableLayout,
      },
    ];

    if (!!product.parking_station) {
      tables = [
        ...tables,
        { text: "AVAILABLE UPON REQUEST", style: "subHeader" },
        {
          style: "tableExample",
          table: {
            widths: ["auto", "auto", "*"],
            headerRows: 1,
            body: [
              [
                { text: "Code", style: "tableHeader" },
                { text: "Quantity", style: "tableHeader" },
                { text: "Description", style: "tableHeader" },
              ],
              [
                {
                  style: "cellStyle",
                  text: product.parking_station.split(" ")[0],
                },
                { style: "cellStyle", text: "1" },
                {
                  style: "cellStyle",
                  text: product.parking_station.substring(
                    product.parking_station.indexOf(" ") + 1
                  ),
                },
              ],
            ],
          },
          layout: blueTableLayout,
        },
      ];
    }
    return tables;
  };

  /////////// TABELLE PRODOTTI  EMPTY ////////////

  const getEmptyCouple = (product) => {
    const code = product.name
      .split("MULTI")
      .join("")
      .split("COMPLETE")
      .join("")
      .split("FIX")
      .join("")
      .split("MOBILE")
      .join("")
      .split("EMPTY")
      .join("")
      .split("- CAP")
      .join("")
      .trim();

    // console.log("code", code);
    // products
    const emptyCouple = products.filter(
      (prod) => prod.name.includes(code) && prod.name.includes("EMPTY")
    );

    return emptyCouple.map((component, i) => {
      return [
        {
          style: "cellStyle",
          text: component.code,
        },
        { style: "cellStyle", text: "1" },
        {
          style: "cellStyle",
          text: component.name,
        },
      ];
    });
  };

  const getEmptiesRelatedCouplings = (filteredCouplings) => {
    return filteredCouplings
      .filter((coup) => !coup[0]?.includes("EC7"))
      .map((coup, i) => {
        const matched = productsConfiguration.find(
          ({ code }) => code === coup[0]
        );

        return [
          [
            {
              style: "cellStyle",
              text: matched.fixed,
            },
            { style: "cellStyle", text: getConfigurationQuantity(coup[0]) },
            {
              style: "cellStyle",
              text: coup[0]?.includes("EC6")
                ? "M " + coup[0] + "*"
                : "M " + coup[0],
            },
          ],
          [
            {
              style: "cellStyle",
              text: matched.mobile,
            },
            { style: "cellStyle", text: getConfigurationQuantity(coup[0]) },
            {
              style: "cellStyle",
              text: coup[0]?.includes("EC6")
                ? "F " + coup[0] + "*"
                : "F " + coup[0],
            },
          ],
        ];
      });
  };

  const getEmptiesRelatedCap = (product) => {
    const code = product.name
      .split("MULTI")
      .join("")
      .split("COMPLETE")
      .join("")
      .split("FIX")
      .join("")
      .split("MOBILE")
      .join("")
      .split("EMPTY")
      .join("")
      .split("- CAP")
      .join("")
      .trim();

    const emptyCouple = products.filter(
      (prod) => prod.name.includes(code) && prod.name.includes("EMPTY")
    );

    const emptyCap = emptyCouple.filter((prod) => prod.cap);

    return emptyCap.map((component, i) => {
      return [
        {
          style: "cellStyle",
          text: component.cap?.split(" ")[0],
        },
        { style: "cellStyle", text: "1" },
        {
          style: "cellStyle",
          text: splitAndShift(component.cap),
        },
      ];
    });
  };

  const getEmptiesRelatedParkingStation = (product) => {
    const code = product.name
      .split("MULTI")
      .join("")
      .split("COMPLETE")
      .join("")
      .split("FIX")
      .join("")
      .split("MOBILE")
      .join("")
      .split("EMPTY")
      .join("")
      .split("- CAP")
      .join("")
      .trim();

    const emptyCouple = products.filter(
      (prod) => prod.name.includes(code) && prod.name.includes("EMPTY")
    );

    const emptyParkingStation = emptyCouple.filter(
      (prod) => prod.parking_station
    );

    return emptyParkingStation.map((component, i) => {
      return [
        {
          style: "cellStyle",
          text: component.parking_station?.split(" ")[0],
        },
        { style: "cellStyle", text: "1" },
        {
          style: "cellStyle",
          text: splitAndShift(component.parking_station),
        },
      ];
    });
  };

  const getEmptiesRelatedProduct = (product, type, couplings, cap) => {
    const relatedRows = products
      .filter(
        (prod) =>
          prod.picture === product.picture &&
          prod.name.includes("EMPTY") &&
          prod.name.includes(type !== "FIX" ? "FIX" : "MOBILE")
      )
      .map((component, i) => {
        return [
          { style: "cellStyle", text: component.code },
          { style: "cellStyle", text: "1" },
          { style: "cellStyle", text: component.name },
        ];
      });

    return relatedRows;
  };

  const getEmptyProductSection = (
    product,
    type,
    filteredCouplings,
    catalogue
  ) => {
    if (catalogue) {
      return [
        { text: "SELECTED PRODUCT", style: "header" },
        {
          table: {
            headerRows: 1,
            widths: ["auto", "auto", "*"],
            body: [
              [
                { text: "Code", style: "tableHeader" },
                { text: "Name", style: "tableHeader" },
                { text: "Description", style: "tableHeader" },
              ],
              [
                {
                  style: "cellStyle",
                  text: product.code,
                },
                { style: "cellStyle", text: product.name },
                {
                  style: "cellStyle",
                  text: product.description,
                },
              ],
            ],
          },
          layout: whiteTableLayout,
        },
        {
          text: `Max operating resistance is ${product.max_resistance}`,
          style: "tableFooter",
        },
        {
          text: "RELATED PRODUCTS",
          style: "subHeader",
        },
        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: ["auto", "auto", "*"],
            body: [
              [
                { text: "Code", style: "tableHeader" },
                { text: "Quantity", style: "tableHeader" },
                { text: "Description", style: "tableHeader" },
              ],
              ...getEmptiesRelatedProduct(product, type),
            ],
          },
          layout: blueTableLayout,
        },
      ];
    } else {
      return [
        { text: "SELECTED PRODUCTS", style: "header" },
        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: ["auto", "auto", "*"],
            body: [
              [
                { text: "Code", style: "tableHeader" },
                { text: "Quantity", style: "tableHeader" },
                { text: "Description", style: "tableHeader" },
              ],
              ...getEmptyCouple(product),
            ],
          },
          layout: blueTableLayout,
        },
        {
          text: "RELATED PRODUCTS(TO BE ORDERED SEPARATELY)",
          style: "subHeader",
        },
        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: ["auto", "auto", "*"],
            body: [
              [
                { text: "Code", style: "tableHeader" },
                { text: "Quantity", style: "tableHeader" },
                { text: "Description", style: "tableHeader" },
              ],
              ...getEmptiesRelatedCouplings(filteredCouplings).flat(),
              ...getEmptiesRelatedCap(product),
              ...getEmptiesRelatedParkingStation(product),
            ],
          },
          layout: blueTableLayout,
        },
        {
          text: "*Other EC with different poles available upon request",
          style: "tableFooter",
        },
      ];
    }
  };

  return {
    info: {
      title: product.name,
      author: "STUCCHI CONFIGURATORE",
    },
    content: [
      ...getConfigurationSection(catalogue, circles),
      getImage(printPage, image),
      ...getProductsSection(product, type, filteredCouplings, catalogue),
    ],
    styles: {
      header: {
        fontSize: 22,
        bold: true,
        color: "#005984",
        margin: [0, 12],
      },
      subHeader: {
        fontSize: 12,
        bold: true,
        color: "#005984",
        margin: [0, 0, 0, 6],
      },
      tableExample: {
        margin: [0, 0, 0, 12],
      },
      tableHeader: {
        color: "white",
        bold: true,
        alignment: "center",
      },
      tableFooter: {
        margin: [0, 4, 0, 12],
        fontSize: 8,
      },
      cellStyle: {
        fontSize: 10,
        alignment: "center",
      },
    },
  };
};

export default formattedPdf;
