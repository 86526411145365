import { mdiChevronDown, mdiChevronUp, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import useComponentVisible from "utils/useComponentVisible";
import "./SelectFilter.scss";

const SelectFilter = (props) => {
  const { ref, setIsComponentVisible, isComponentVisible, toggling } =
    useComponentVisible(false);

  const handleChange = (option) => {
    props.onChange(option);
    setIsComponentVisible(false);
  };

  const handleReset = (event) => {
    event.stopPropagation();
    setIsComponentVisible(false);
    props.onChange(null);
  };

  return (
    <div
      ref={ref}
      className={`catalogue-filters__select ${
        isComponentVisible ? "active" : ""
      }`}
    >
      <div className="value-select" onClick={toggling}>
        <div className="placeholder">
          {props.value ? props.value : props.placeholder}
        </div>

        {props.value ? (
          <Icon path={mdiClose} size={1} onClick={handleReset} />
        ) : (
          <Icon
            path={isComponentVisible ? mdiChevronUp : mdiChevronDown}
            size={1}
          />
        )}
      </div>

      {isComponentVisible && (
        <div className="options-container">
          {props.options.map((option, i) => (
            <div
              key={`option_${i}`}
              onClick={() => handleChange(option)}
              className="option"
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectFilter;
