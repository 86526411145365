import Navbar from "components/navbar/Navbar";
import products from "db/products.json";
import { useEffect, useState } from "react";
import DetailProduct from "components/detail-product/DetailProduct";
import "./Catalogue.scss";
import SelectFilter from "./select-filter/SelectFilter";
import { Link } from "react-router-dom";
import useComponentVisible from "utils/useComponentVisible";

const Catalogue = ({ history }) => {
  const { ref, isComponentVisible, open, close } = useComponentVisible(false);
  const [limit, setLimit] = useState(50);
  const [query, setQuery] = useState("");
  const [series, setSeries] = useState(null);
  const [lines, setLines] = useState(null);

  // to avoid lag
  useEffect(() => setTimeout(() => setLimit(null), 2000), []);

  const extractSingleValues = (type) =>
    products
      .map((element) => element[type])
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .sort((a, b) => a - b);

  return (
    <div
      className={
        "catalogue-view" + (history.action === "PUSH" ? " animate" : "")
      }
    >
      {isComponentVisible && (
        <DetailProduct
          propRef={ref}
          product={isComponentVisible}
          close={close}
          catalogue={true}
        />
      )}

      <Navbar title="Configured models available" />

      <div className="catalogue-list">
        <div className="container section">
          <div className="catalogue__filters">
            <input
              type="text"
              placeholder="Search Product..."
              onChange={({ target }) => setQuery(target.value)}
            />

            <SelectFilter
              width="200px"
              value={series}
              placeholder="Series"
              onChange={setSeries}
              options={extractSingleValues("series")}
            />

            <SelectFilter
              value={lines}
              placeholder="Lines"
              onChange={setLines}
              options={extractSingleValues("total_lines")}
            />
          </div>

          <table className="catalogue-table">
            <thead>
              <tr>
                <th>code</th>
                <th style={{ textAlign: "left" }}>name</th>
                <th>total lines</th>
                <th>series</th>
              </tr>
            </thead>

            <tbody>
              {products
                .filter((product) => filterProducts(product.series, series))
                .filter((product) => filterProducts(product.total_lines, lines))
                .filter((product) => searchProducts(product, query))
                .slice(0, limit || products.length)
                .map((product, i) => (
                  <tr key={i}>
                    <td>{product.code}</td>
                    <td>{product.name}</td>
                    <td style={{ textAlign: "center" }}>
                      {product.total_lines}
                    </td>
                    <td style={{ textAlign: "center" }}>{product.series}</td>
                    <td>
                      <div className="btn" onClick={() => open(product)}>
                        details
                      </div>
                    </td>
                    {/* <td>
                      <Link
                        to={{ pathname: "/form", state: product }}
                        className="btn outline"
                      >
                        request
                      </Link>
                    </td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Catalogue;

const searchProducts = (product, query) =>
  Object.values(product).some((value) => {
    return value.toString().toLowerCase().includes(query.toLowerCase());
  });

const filterProducts = (element, value) =>
  value === null ? element : element === value;
