import Navbar from "components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { findMatching } from "utils/products";
// import MatchResult from "./match-result/MatchResult";
import ResultsList from "./results-list/ResultsList";
import Specs from "./specs/Specs";
import "./Results.scss";
import { setCircles, setOperatorMode, setReduxResults } from "reducer";
import { useDispatch } from "react-redux";
import { decode } from "utils/encodeDecode";

const resultsConfiguration = [
  {
    label: "Complete Perfect",
    key: "completePerfect",
    subKey: "complete",
  },
  {
    label: "Complete Different Thread",
    key: "completeDifferentThread",
    subKey: "complete",
  },
  {
    label: "Complete Different Port",
    key: "completeDifferentPort",
    subKey: "complete",
  },
  {
    label: "Complete Different Port Or Thread",
    key: "completeDifferentPortOrThread",
    subKey: "complete",
  },
  {
    label: "Empty Perfect",
    key: "emptyPerfect",
    subKey: "empty",
    couple: true,
  },
  {
    label: "Empty Total Lines Plus One",
    key: "emptyTotalLinesPlusOne",
    subKey: "empty",
    isNotVisibleIfOneInch: true,
    couple: true,
  },
  {
    label: "Empty Total Lines Plus Two",
    key: "emptyTotalLinesPlusTwo",
    subKey: "empty",
    isNotVisibleIfOneInch: true,
    couple: true,
  },
  {
    label: "Empty With More Total Lines",
    key: "emptyTotalLinesPlusN",
    subKey: "empty",
    isNotVisiblebByDefault: true,
    couple: true,
  },
  {
    label: "Complete Body Size Plus One",
    key: "completeBodySizePlusOne",
    subKey: "complete",
  },
  {
    label: "Complete Body Size Plus One Different Thread",
    key: "completeBodySizePlusOneDifferentThread",
    subKey: "complete",
  },
  {
    label: "Complete Body Size Plus One Different Port",
    key: "completeBodySizePlusOneDifferentPort",
    subKey: "complete",
  },
  {
    label: "Complete Body Size Plus One Different Port Or Thread",
    key: "completeBodySizePlusOneDifferentPortOrThread",
    subKey: "complete",
  },
  {
    label: "Empty Body Size Plus One",
    key: "emptyBodySizePlusOne",
    subKey: "empty",
    isNotVisibleIfOneInch: true,
    couple: true,
  },
  {
    label: "Empty Body Size Plus One Total Lines Plus One",
    key: "emptyBodySizePlusOneTotalLinesPlusOne",
    subKey: "empty",
    isNotVisibleIfOneInch: true,
    couple: true,
  },
  {
    label: "Empty Body Size Plus One Total Lines Plus Two",
    key: "emptyBodySizePlusOneTotalLinesPlusTwo",
    subKey: "empty",
    isNotVisibleIfOneInch: true,
    couple: true,
  },
  {
    label: "Empty Body Size Plus One With More Total Lines",
    key: "emptyBodySizePlusOneTotalLinesPlusN",
    subKey: "empty",
    isNotVisiblebByDefault: true,
    couple: true,
  },
];
const Results = ({ history, location }) => {
  const dispatch = useDispatch();
  const placcaCode = new URLSearchParams(location.search).get("placca");
  const isOperatorFromUrl = new URLSearchParams(location.search).get(
    "operator"
  );

  // console.log("placcaCode", placcaCode);
  const [results, setResults] = useState(null);
  const [firstValidSetting, setFirstValidSetting] = useState(null);
  const circles = useSelector(({ circles }) => circles);
  const isOperator =
    useSelector(({ operator_mode }) => operator_mode) ||
    localStorage.getItem("operator_mode");

  const hasCirclesOneInchConfig = circles.find(
    (circle) => circle?.setting?.body_size.label === '1"'
  );
  // const [disclaimer, setDisclaimer] = useState(false);

  useEffect(() => {
    if (!!isOperatorFromUrl) {
      dispatch(setOperatorMode());
    }
  }, [isOperatorFromUrl]);

  useEffect(() => {
    if (placcaCode) {
      const decodedCircles = decode(placcaCode);

      setResults(findMatching(decodedCircles));
      dispatch(setReduxResults(findMatching(decodedCircles)));
    } else {
      if (circles.every((circle) => !circle)) {
        history.push("/");
      } else {
        const decodedCircles = findMatching(circles);
        setResults(decodedCircles);

        dispatch(setReduxResults(decodedCircles));
      }
    }
  }, [circles, history]);
  useEffect(() => {
    if (placcaCode) {
      dispatch(setCircles(decode(placcaCode)));
    }
  }, []);

  useEffect(() => {
    if (!!results) {
      console.log("results", results);
      const firstResult = Object.entries(results).find(
        (res) => !!res[1] && Object.keys(res[1]).length !== 0
      );
      setFirstValidSetting(!!firstResult ? firstResult[0] : "completePerfect");
    }
  }, [results]);

  return (
    <div
      className={"results-view" + (history.action === "PUSH" ? " animate" : "")}
    >
      <Navbar title="Your Configuration" />

      <div className="results-view__intro container section">
        <div className="intro__single-result-spec">
          {circles.find((value) => value?.type === "hydraulic") && (
            <Specs circles={circles} type="hydraulic" />
          )}

          {circles.find((value) => value?.type === "electric") && (
            <Specs circles={circles} type="electric" />
          )}
        </div>
      </div>

      <div className="results-view__list">
        <div className="container section">
          <div className="list__title">Proposed Products</div>
          {results &&
            resultsConfiguration
              .filter((config) =>
                hasCirclesOneInchConfig
                  ? !config.isNotVisibleIfOneInch
                  : !config.isNotVisiblebByDefault
              )
              .filter((config) =>
                !isOperator ? config.key === firstValidSetting : config
              )
              .map((config) => (
                <>
                  <div className="list__title">{config.label}</div>
                  <ResultsList
                    couple={config.couple}
                    results={
                      !!results &&
                      !!results[config.key] &&
                      results[config.key][config.subKey]
                    }
                  />
                </>
              ))}

          {/* <div className="list__title">Complete Perfect</div>
          <ResultsList results={results?.completePerfect?.complete} title="" />
          <div className="list__title">Complete Different Thread</div>
          <ResultsList
            results={results?.completeDifferentThread?.complete}
            title=""
          />
          <div className="list__title">Complete Different Port</div>
          <ResultsList
            results={results?.completeDifferentPort?.complete}
            title=""
          />
          <div className="list__title">Complete Different Port Or Thread</div>
          <ResultsList
            results={results?.completeDifferentPortOrThread?.complete}
            title=""
          />
          <div className="list__title">Empty Perfect</div>
          <ResultsList results={results?.emptyPerfect?.empty} couple title="" />
          {!hasCirclesOneInchConfig ? (
            <>
              <div className="list__title">Empty Total Lines Plus One</div>
              <ResultsList
                results={results?.emptyTotalLinesPlusOne?.empty}
                couple
                title=""
              />
              <div className="list__title">Empty Total Lines Plus Two</div>
              <ResultsList
                results={results?.emptyTotalLinesPlusTwo?.empty}
                couple
                title=""
              />
            </>
          ) : (
            <>
              <div className="list__title">Empty With More Total Lines</div>
              <ResultsList
                results={results?.emptyTotalLinesPlusN?.empty}
                couple
                title=""
              />
            </>
          )} */}
          {/* <div className="list__title">
            Empty Total Lines Plus One Different EC
          </div>
          <ResultsList
            results={results?.emptyTotalLinesPlusOneDifferentEc?.empty}
            couple
            title=""
          /> */}
          {/* <div className="list__title">
            Empty Total Lines Plus Two Different EC
          </div>
          <ResultsList
            results={results?.emptyTotalLinesPlusTwoDifferentEc?.empty}
            couple
            title=""
          /> */}
          {/* <div className="list__title">Complete Body Size Plus One</div>
          <ResultsList
            results={results?.completeBodySizePlusOne?.complete}
            title=""
          />
          <div className="list__title">
            Complete Body Size Plus One Different Thread
          </div>
          <ResultsList
            results={results?.completeBodySizePlusOneDifferentThread?.complete}
            title=""
          />
          <div className="list__title">
            Complete Body Size Plus One Different Port
          </div>
          <ResultsList
            results={results?.completeBodySizePlusOneDifferentPort?.complete}
            title=""
          />
          <div className="list__title">
            Complete Body Size Plus One Different Port Or Thread
          </div>
          <ResultsList
            results={
              results?.completeBodySizePlusOneDifferentPortOrThread?.complete
            }
            title=""
          /> */}
          {/* {!hasCirclesOneInchConfig ? (
            <>
              <div className="list__title">Empty Body Size Plus One</div>
              <ResultsList
                results={results?.emptyBodySizePlusOne?.empty}
                couple
                title=""
              />

              <div className="list__title">
                Empty Body Size Plus One Total Lines Plus One
              </div>
              <ResultsList
                results={results?.emptyBodySizePlusOneTotalLinesPlusOne?.empty}
                couple
                title=""
              /> */}

          {/* <div className="list__title">
            Empty Body Size Plus One Total Lines Plus One Different EC
          </div>
          <ResultsList
            results={
              results?.emptyBodySizePlusOneTotalLinesPlusOneDifferentEc?.empty
            }
            couple
            title=""
          /> */}

          {/* <div className="list__title">
                Empty Body Size Plus One Total Lines Plus Two
              </div>
              <ResultsList
                results={results?.emptyBodySizePlusOneTotalLinesPlusTwo?.empty}
                couple
                title=""
              /> */}
          {/* <div className="list__title">
            Empty Body Size Plus One Total Lines Plus Two Different EC
          </div>
          <ResultsList
            results={
              results?.emptyBodySizePlusOneTotalLinesPlusTwoDifferentEc?.empty
            }
            couple
            title=""
          /> */}
          {/* </>
          ) : (
            <>
              <div className="list__title">
                Empty Body Size Plus One With More Total Lines
              </div>
              <ResultsList
                results={results?.emptyBodySizePlusOneTotalLinesPlusN?.empty}
                couple
                title=""
              />
            </>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Results;
