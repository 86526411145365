import React from "react";
import styled from "styled-components";

import "./Popup.scss";

const Container = styled.div`
  display: flex;
  background-color: #00000050;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh !important;
  width: 100vw !important;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const StyledCard = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: scroll;
  width: ${(props) => props.cardWidth || "50%"};
  align-items: ${(props) => props.cardAlignItems};
  justify-content: space-between;
  background-color: ${(props) => props.backgroundColor || "#fff"};
  border-radius: 16px;
  padding: ${(props) => props.paddingCard || "32px"};
  min-height: ${(props) => props.minHeight || "180px"};
  max-height: ${(props) => props.maxHeight || "75vh"};
  @media (max-width: 1580px) {
    width: 70%;
  }

  @media (max-width: 1024px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    padding: ${(props) => props.paddingCardMobile || "24px"};
    width: 90%;
  }
  @media (max-width: 564px) {
    padding: ${(props) => props.paddingCardMobile || "24px 24px"};
    width: 90%;
  }
`;
export const Popup = (props) => {
  return (
    <Container {...props}>
      <StyledCard className="popup" {...props}>
        {props.children}
      </StyledCard>
    </Container>
  );
};
