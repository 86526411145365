import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48px"
    height="48px"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M12 5.177 20.631 21H3.369L12 5.177zM12 1 0 23h24L12 1zm-1 9h2v6h-2v-6zm1 9.75a1.251 1.251 0 0 1 0-2.5 1.251 1.251 0 0 1 0 2.5z" />
  </svg>
);
export default SvgComponent;
